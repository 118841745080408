import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IndexService } from '../../_Service/Index/index.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { environment } from '../../../environments/environment';

import * as moment from 'moment-timezone';
import { DatePipe } from '@angular/common';

import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'full-layout',
    templateUrl: './full.component.html',
    styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {

    color = 'defaultdark';
    showSettings = false;
    showMinisidebar = false;
    showDarktheme = false;
    platform_name: any;

    public config: PerfectScrollbarConfigInterface = {};
    isChatPage: boolean;
    showRouterOutlet: boolean = true;
    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private _IndexService: IndexService,
        private datePipe: DatePipe
    ) {
        if ((this.router.url.includes("/news")) || (this.router.url.includes("/blog")) || (this.router.url.includes("/matches/"))) {
            this.showRouterOutlet = false;
        }

        this.year = '';
        this.platform_name = environment.platform_name;
        this.removejscssfile("normalize.css", "css");
        this.removejscssfile("components.css", "css");
        this.removejscssfile("its-game-time.css", "css");
        this.removejscssfile("its-game-time.js", "js");

        if (this.router.url.includes('eventChat')) {
            this.isChatPage = true;
        } else {
            this.isChatPage = false;
        }

        //Current Geo Details By User Login(a)
        if (sessionStorage.getItem('fullcomponent') && this.decryptData(sessionStorage.getItem('fullcomponent')) == 'yes') {
        } else {
            sessionStorage.setItem('fullcomponent', this.encryptData('yes'));
            this._IndexService.currentGeoDetails().subscribe(res => {
                if (res && res['geo'] && res['geo']['timezone']) {

                    localStorage.setItem('geoDetails', this.encryptData(JSON.stringify(res)));

                    this.timezone = res['geo']['timezone'];
                    let created_at = moment(new Date()).tz(this.timezone);
                    this.year = this.datePipe.transform(created_at._d, "yyyy");
                }
            });
        }
        //End Current Geo Details By User Login(a)
    }
    addClass: any;
    //Timezone
    timezone: any;
    year: any = '';

    removejscssfile(filename, filetype) {
        let targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none" //determine element type to create nodelist from
        let targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none" //determine corresponding attribute to test for
        let allsuspects = document.getElementsByTagName(targetelement)
        for (let i = allsuspects.length; i >= 0; i--) { //search backwards within nodelist for matching elements to remove
            if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1)
                allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
        }
    }

    ngOnInit() {

        //Timezone
        this.timezone = '';

        this.year = '';

        if (this.router.url == '/messageChat') {
            this.addClass = "W-100";
        } else {
            this.addClass = "";
        }

        if (this.router.url === '/') {

        }


    }

    decryptData(data) {
        try {
            const bytes = CryptoJS.AES.decrypt(data, '1234509876encryptdecrypt1234567890');
            if (bytes.toString()) {
                if (CryptoJS.enc.Utf8) {
                    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                }
            }
            if (data) {
                return data;
            }

        } catch (e) {
        }
    }

    encryptData(data) {

        try {
            return CryptoJS.AES.encrypt(JSON.stringify(data), '1234509876encryptdecrypt1234567890').toString();
        } catch (e) {
        }
    }

    ngDoCheck() {
        if (this.router.url.includes('eventChat')) {
            this.isChatPage = true;
        } else {
            this.isChatPage = false;
        }
    }

    ngAfterViewInit() {
        window.scrollTo(0, 0);
    }

    ngOnDestroy() {
        this.removejscssfile("normalize.css", "css");
        this.removejscssfile("components.css", "css");
        this.removejscssfile("its-game-time.css", "css");
        this.removejscssfile("its-game-time.js", "js");
    }
}
