import { Injectable } from '@angular/core';
import {
  HttpClient
} from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EditProfileService {

  constructor(private Http: HttpClient) { }

  insertWorkEducation(Data) {
    return this.Http.post(`${environment.base_url}/insertWorkEducation`, Data);
  }

  getWorkEducation() {
    return this.Http.get(`${environment.base_url}/getWorkEducation`);
  }

  getSingleWorkEdu(Data) {
    return this.Http.get(`${environment.base_url}/getSingleWorkEdu?id=` + Data.id);
  }

  deleteWorkEdu(Data) {
    return this.Http.post(`${environment.base_url}/deleteWorkEdu`, Data);
  }

  insertPlacesLived(Data) {
    return this.Http.post(`${environment.base_url}/insertPlacesLived`, Data);
  }

  getPlacesLived() {
    return this.Http.get(`${environment.base_url}/getPlacesLived`);
  }

  deletePlacesLived(Data) {
    return this.Http.post(`${environment.base_url}/deletePlacesLived`, Data);
  }

  getLoginUserMyProfileDetails() {
    return this.Http.get(`${environment.base_url}/getLoginUserMyProfileDetails`);
  }

  // Tag Team Group(p)
  getMainProfileUsers(Data) {
    return this.Http.post(`${environment.base_url}/getMainProfileUsers`, Data);
  }

  getAliasProfileUsers(Data) {
    return this.Http.post(`${environment.base_url}/getAliasProfileUsers`, Data);
  }

  GetTagTeamGroupsList(Data) {
    return this.Http.post(`${environment.base_url}/getTagTeamGroupsList`, Data);
  }

  // GetTagTeamGroupList() {
  //   return this.Http.get(`${environment.base_url}/getTagTeamGroupList`)
  // }

  ConnectionandFollowerList(Data) {
    return this.Http.post(`${environment.base_url}/getTagTeamUsersTypewise`, Data);
  }

  AddTagTeamGroup(Data) {
    return this.Http.post(`${environment.base_url}/addTagTeamGroup`, Data);
  }

  DeleteGroup(data) {
    return this.Http.post(`${environment.base_url}/deleteGroup`, data);
  }

  GroupDetail(data) {
    return this.Http.post(`${environment.base_url}/getGroupDetails`, data);
  }

  deleteGroupMember(data) {
    return this.Http.post(`${environment.base_url}/deleteGroupMember`, data);
  }

  RemainingMembers(data) {
    return this.Http.post(`${environment.base_url}/remainingMembers`, data);
  }

  changeGroupName(data) {
    return this.Http.post(`${environment.base_url}/changeGroupName`, data);
  }

  SaveNewGroupMembers(data) {
    return this.Http.post(`${environment.base_url}/addGroupMembers`, data);
  }

  GroupMemberNotification(data) {
    return this.Http.post(`${environment.base_url}/groupMemberNotification`, data);
  }

  GetGroupsListTypeWise(data) {
    return this.Http.post(`${environment.base_url}/getGroupsListTypeWise`, data);
  }

  verifyPhone(data) {
    return this.Http.post(`${environment.base_url}/verifyPhone`, data);
  }

  updatePhoneInfo(data) {
    return this.Http.post(`${environment.base_url}/updatePhoneInfo`, data);
  }

  verifyEmail(data) {
    return this.Http.post(`${environment.base_url}/verifyEmail`, data);
  }

  updateEmailInfo(data) {
    return this.Http.post(`${environment.base_url}/updateEmailInfo`, data);
  }

  clickSendEmailEditProfile(Data) {
    return this.Http.post(`${environment.social_media_url}/clickSendEmailEditProfile`, Data);
  }

  userNameSearchEngineSettings(Data) {
    return this.Http.post(`${environment.base_url}/userNameSearchEngineSettings`, Data);
  }

  updatePlacePrivacy(Data) {
    return this.Http.post(`${environment.base_url}/updatePlacePrivacy`, Data);
  }
  insertsportexp(Data) {
    return this.Http.post(`${environment.base_url}/insertsportexp`, Data);
  }
  getallsport(Data) {
    return this.Http.post(`${environment.base_url}/getallsport`, Data);
  }
  deletesportexp(Data) {
    return this.Http.post(`${environment.base_url}/deletesportexp`, Data);
  }
  getsportexp(Data) {
    return this.Http.post(`${environment.base_url}/getsportexp`, Data);
  }
  insertcoachinfo(Data) {
    return this.Http.post(`${environment.base_url}/insertcoachinfo`, Data);
  }
  getallcoachdetail(Data) {
    return this.Http.post(`${environment.base_url}/getallcoachdetail`, Data);
  }
  getcoachdetailbyid(Data) {
    return this.Http.post(`${environment.base_url}/getcoachdetailbyid`, Data);
  }
  deletecoachinfo(Data) {
    return this.Http.post(`${environment.base_url}/deletecoachinfo`, Data);
  }
  getachivementdetailbyid(Data) {
    return this.Http.post(`${environment.base_url}/getachivementdetailbyid`, Data);
  }
  deleteachivementinfo(Data) {
    return this.Http.post(`${environment.base_url}/deleteachivementinfo`, Data);
  }
  getallachivementdetail(Data) {
    return this.Http.post(`${environment.base_url}/getallachivementdetail`, Data);
  }
  insertachivement(Data) {
    return this.Http.post(`${environment.base_url}/insertachivement`, Data);
  }
  updateprivacy(Data) {
    return this.Http.post(`${environment.base_url}/updateprivacy`, Data);
  }

  getPlayerUserInfo() {
    return this.Http.get(`${environment.base_url}/getPlayerUserInfo`);
  }

  addUpdatePlayerUserInfo(Data) {
    return this.Http.post(`${environment.base_url}/addUpdatePlayerUserInfo`, Data);
  }

  updatePlayerWidgetSetting(Data) {
    return this.Http.post(`${environment.base_url}/updatePlayerWidgetSetting`, Data);
  }

  addUpdateProfileUpgradeData(Data) {
    return this.Http.post(`${environment.base_url}/addUpdateProfileUpgradeData`, Data);
  }

  getProfileUpgradeData() {
    return this.Http.get(`${environment.base_url}/getProfileUpgradeData`);
  }

  deleteProfileUpgradeData(Data) {
    return this.Http.post(`${environment.base_url}/deleteProfileUpgradeData`, Data);
  }

  addUpdateProfileUpgradeDataFollowUp() {
    return this.Http.get(`${environment.base_url}/addUpdateProfileUpgradeDataFollowUp`);
  }

  addFindSchoolRequest(data) {
    return this.Http.post(`${environment.base_url}/addFindSchoolRequest`, data);
  }

  deleteTeamUpgradeData(Data) {
    return this.Http.post(`${environment.base_url}/deleteTeamUpgradeData`, Data);
  }
  
  getAllCustomPlayers(Data) {
    return this.Http.post(`${environment.base_url}/getAllCustomPlayers`, Data);
  }

  managePlayerOrCoach(Data) {
    return this.Http.post(`${environment.base_url}/managePlayerOrCoach`, Data);
  }
  
  manageProfileRquests(Data) {
    return this.Http.post(`${environment.base_url}/manageProfileRquests`, Data);
  }
}

