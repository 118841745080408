import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_Service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLoggedIn = this._authService.loggedIn();
    const routeUrl0 = (route && route.url && route.url[0] && route.url[0].path) ? route.url[0].path : '';
    if (isLoggedIn) {
      switch (routeUrl0) {
        case '':
        case 'home':
          this._router.navigateByUrl('index');
          break;
        case 'aliasPostViewDetail':
        case 'postViewDetail':
          this._router.navigateByUrl('/postDetails?string=' + route.queryParams['string']);
          break;
        case 'followUserProfile':
          let ID = this._authService.decryptData(route.queryParams['q']);
          let encId = this._authService.encryptData(ID);
          this._router.navigateByUrl('/userProfile?q=' + encodeURIComponent(encId));
      }
      return false;
    } else {
      return true;
    }
  }

}
