export const customEmojisTennis: any = [
  // {
  //   name: 'Netball',
  //   shortNames: ['netball'],
  //   custom: true,
  //   text: '(netball)',
  //   emoticons: [],
  //   keywords: ['net', 'ball'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665765845-ping-pong.png',
  // },
  // {
  //   name: 'Trophy',
  //   shortNames: ['trophy'],
  //   custom: true,
  //   text: '(trophy)',
  //   emoticons: [],
  //   keywords: ['trophy'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665766168-trophy-1.png',
  // },
  // {
  //   name: 'Medal',
  //   shortNames: ['medal'],
  //   custom: true,
  //   text: '(medal)',
  //   emoticons: [],
  //   keywords: ['medal'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665767475-gold-medal.png',
  // },
  // {
  //   name: 'Netplayer',
  //   shortNames: ['net-player'],
  //   custom: true,
  //   text: '(net-player)',
  //   emoticons: [],
  //   keywords: ['net-player'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665767782-block.png',
  // },
  // {
  //   name: 'Tennisgirl',
  //   shortNames: ['tennis-girl'],
  //   custom: true,
  //   text: '(tennis-girl)',
  //   emoticons: [],
  //   keywords: ['tennis-girl'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665767928-tennis.png',
  // },
  // {
  //   name: 'Racketball',
  //   shortNames: ['racket-ball'],
  //   custom: true,
  //   text: '(racket-ball)',
  //   emoticons: [],
  //   keywords: ['racket', 'ball'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665768346-tennis-2.png',
  // },
  // {
  //   name: 'Tennisboy',
  //   shortNames: ['tennis-boy'],
  //   custom: true,
  //   text: '(tennis-boy)',
  //   emoticons: [],
  //   keywords: ['tennis', 'boy', 'racket'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665768607-tennis-player.png',
  // },
  // {
  //   name: 'Racketandball',
  //   shortNames: ['racket-and-ball'],
  //   custom: true,
  //   text: '(racket-and-ball)',
  //   emoticons: [],
  //   keywords: ['racket', 'racketandball', 'ball'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665769155-basketball-1.png',
  // },
  // {
  //   name: 'Tennisball',
  //   shortNames: ['tennis-ball'],
  //   custom: true,
  //   text: '(tennis-ball)',
  //   emoticons: [],
  //   keywords: ['tennis', 'ball'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665769342-tennis-3.png',
  // },
  // {
  //   name: 'Tenniscourt',
  //   shortNames: ['tennis-court'],
  //   custom: true,
  //   text: '(tennis-court)',
  //   emoticons: [],
  //   keywords: ['tennis court'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665769670-net.png',
  // },
  // {
  //   name: 'Tennisbag',
  //   shortName: ['tennis-bag'],
  //   custom: true,
  //   text: ('tennis-bag'),
  //   emoticon: [],
  //   keywords: ['tennis', 'bag'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096148-bag.png',
  // },
  // {
  //   name: 'Sportsball',
  //   shortName: ['sports-ball'],
  //   custom: true,
  //   text: ('sports-ball'),
  //   emoticon: [],
  //   keywords: ['sportsball'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096149-ball-sports.png',
  // },
  // {
  //   name: 'Tennisplayer',
  //   shortName: ['tennis-player'],
  //   custom: true,
  //   text: ('tennis-player'),
  //   emoticon: [],
  //   keywords: ['tennisplayer'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096149-block.png',
  // },
  // {
  //   name: 'Competition',
  //   shortName: ['competition'],
  //   custom: true,
  //   text: ('competition'),
  //   emoticon: [],
  //   keywords: ['competition'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096149-competition-1.png',
  // },
  // {
  //   name: 'Double',
  //   shortName: ['double'],
  //   custom: true,
  //   text: ('double'),
  //   emoticon: [],
  //   keywords: ['double'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096149-double.png',
  // },
  // {
  //   name: 'Lawntennis',
  //   shortName: ['lawn-tennis'],
  //   custom: true,
  //   text: ('lawn-tennis'),
  //   emoticon: [],
  //   keywords: ['lawn', 'tennis'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096149-lawn-tennis.png',
  // },
  // {
  //   name: 'Net',
  //   shortName: ['net'],
  //   custom: true,
  //   text: ('net'),
  //   emoticon: [],
  //   keywords: ['net'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096149-net.png',
  // },
  // {
  //   name: 'Pairtennis',
  //   shortName: ['pair-tennis'],
  //   custom: true,
  //   text: ('Pair-tennis'),
  //   emoticon: [],
  //   keywords: ['pair', 'tennis'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096149-pair.png',
  // },
  // {
  //   name: 'Score',
  //   shortName: ['score'],
  //   custom: true,
  //   text: ('score'),
  //   emoticon: [],
  //   keywords: ['score'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096217-score.png',
  // },
  // {
  //   name: 'Shoes',
  //   shortName: ['shoes'],
  //   custom: true,
  //   text: ('shoes'),
  //   emoticon: [],
  //   keywords: ['shoes'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096217-shoes.png',
  // },
  // {
  //   name: 'Sportsclothes',
  //   shortName: ['sports-clothes'],
  //   custom: true,
  //   text: ('sports-clothes'),
  //   emoticon: [],
  //   keywords: ['sports', 'clothes'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096217-sport-clothes.png',
  // },
  // {
  //   name: 'Tabletannis',
  //   shortName: ['table-tennis'],
  //   custom: true,
  //   text: ('table-tennis'),
  //   emoticon: [],
  //   keywords: ['table-tennis'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096217-table-tennis-3---Copy.png',
  // },
  // {
  //   name: 'Tennis',
  //   shortName: ['tennis'],
  //   custom: true,
  //   text: ('tennis'),
  //   emoticon: [],
  //   keywords: ['tennis'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096218-tennis-1.png',
  // },
  // {
  //   name: 'Batandball',
  //   shortName: ['batandball'],
  //   custom: true,
  //   text: ('batandball'),
  //   emoticon: [],
  //   keywords: ['bat', 'and', 'ball'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096218-tennis-2.png',
  // },
  // {
  //   name: 'player',
  //   shortName: ['player'],
  //   custom: true,
  //   text: ('player'),
  //   emoticon: [],
  //   keywords: ['player'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096230-tennis-3.png',
  // },
  // {
  //   name: 'Tennisbat',
  //   shortName: ['tennis-bat'],
  //   custom: true,
  //   text: ('tennis-bat'),
  //   emoticon: [],
  //   keywords: ['tennis', 'bat'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096230-tennis-4.png',
  // },
  // {
  //   name: 'Handtennis',
  //   shortName: ['handtennis'],
  //   custom: true,
  //   text: ('handtennis'),
  //   emoticon: [],
  //   keywords: ['hand', 'tennis'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096230-tennis-5.png',
  // },
  // {
  //   name: 'Girltennisplayer',
  //   shortName: ['girl-tennis-player'],
  //   custom: true,
  //   text: ('girl-tennis-player'),
  //   emoticon: [],
  //   keywords: ['girl', 'tennis', 'player'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096230-tennis-6.png',
  // },
  // {
  //   name: 'Boytennisplayer',
  //   shortName: ['boy-tennis-player'],
  //   custom: true,
  //   text: ('boy-tennis-player'),
  //   emoticon: [],
  //   keywords: ['boy', 'tennis', 'player'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096230-tennis-7.png',
  // },
  // {
  //   name: 'Live',
  //   shortName: ['live'],
  //   custom: true,
  //   text: ('live'),
  //   emoticon: [],
  //   keywords: ['live'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096230-tennis-9---Copy.png',
  // },
  // {
  //   name: 'Ground',
  //   shortName: ['ground'],
  //   custom: true,
  //   text: ('gronud'),
  //   emoticon: [],
  //   keywords: ['ground'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096230-tennis-10.png',
  // },
  // {
  //   name: 'Tennisontv',
  //   shortName: ['tennisontv'],
  //   custom: true,
  //   text: ('tennisontv'),
  //   emoticon: [],
  //   keywords: ['tennisontv'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096506-tennis-11.png',
  // },
  // {
  //   name: 'Livesystem',
  //   shortName: ['live-system'],
  //   custom: true,
  //   text: ('live-system'),
  //   emoticon: [],
  //   keywords: ['live', 'system'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096506-tennis-14---Copy.png',
  // },
  // {
  //   name: 'Tennisballbucket',
  //   shortName: ['tennis-ball-bucket'],
  //   custom: true,
  //   text: ('tennis-ball-bucket'),
  //   emoticon: [],
  //   keywords: ['tennisball','tennis-ball-bucket'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096506-tennis-ball.png',
  // },
  
  // {
  //   name: 'Crosstennisplayer',
  //   shortName: ['cross-tennis-player'],
  //   custom: true,
  //   text: ('cross-tennis-player'),
  //   emoticon: [],
  //   keywords: ['cross', 'tennis', 'player'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096519-tennis-player-4.png',
  // },
  // {
  //   name: 'Girlplayer',
  //   shortName: ['girl-player'],
  //   custom: true,
  //   text: ('girl-player'),
  //   emoticon: [],
  //   keywords: ['girl', 'player'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096519-tennis-player-5.png',
  // },
  // {
  //   name: 'Girlandboy',
  //   shortName: ['girl-and-boy'],
  //   custom: true,
  //   text: ('girl-and-boy'),
  //   emoticon: [],
  //   keywords: ['girl', 'and', 'boy'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096519-tennis-player-6.png',
  // },
  // {
  //   name: 'Girl',
  //   shortName: ['girl'],
  //   custom: true,
  //   text: ('girl'),
  //   emoticon: [],
  //   keywords: ['girl'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096520-tennis-player-8.png',
  // },
  // {
  //   name: 'Tennisplayerbatball',
  //   shortName: ['tennis-player-bat-ball'],
  //   custom: true,
  //   text: ('tennis-player-bat-ball'),
  //   emoticon: [],
  //   keywords: ['tennis', 'player', 'bat', 'ball'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096520-tennis-player-9.png',
  // },
  // {
  //   name: 'Tie',
  //   shortName: ['tie'],
  //   custom: true,
  //   text: ('tie'),
  //   emoticon: [],
  //   keywords: ['tie'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096521-tie.png',
  // },
  // {
  //   name: 'Tournament',
  //   shortName: ['tournament'],
  //   custom: true,
  //   text: ('tournament'),
  //   emoticon: [],
  //   keywords: ['tournament'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096521-tournament.png',
  // },
  // {
  //   name: 'Visor',
  //   shortName: ['visor'],
  //   custom: true,
  //   text: ('visor'),
  //   emoticon: [],
  //   keywords: ['visor'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096521-visor.png',
  // },
  // {
  //   name: 'Waterbottle',
  //   shortName: ['water-bottle'],
  //   custom: true,
  //   text: ('water-bottle'),
  //   emoticon: [],
  //   keywords: ['water,bottle'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096521-water-bottle.png',
  // },
  // {
  //   name: 'Advantage',
  //   shortName: ['advantage'],
  //   custom: true,
  //   text: ('advantage'),
  //   emoticon: [],
  //   keywords: ['advantage'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666170966-icons8-advantage-64.png',
  // },
  // {
  //   name: 'Break',
  //   shortName: ['break'],
  //   custom: true,
  //   text: ('break'),
  //   emoticon: [],
  //   keywords: ['break'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666170966-icons8-break-64.png',
  // },
  // {
  //   name: 'Call',
  //   shortName: ['call'],
  //   custom: true,
  //   text: ('call'),
  //   emoticon: [],
  //   keywords: ['call'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666170966-icons8-call-64.png',
  // },
  // {
  //   name: 'Career',
  //   shortName: ['career'],
  //   custom: true,
  //   text: ('career'),
  //   emoticon: [],
  //   keywords: ['career'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666170966-icons8-career-64.png',
  // },
  // {
  //   name: 'Chair',
  //   shortName: ['chair'],
  //   custom: true,
  //   text: ('chair'),
  //   emoticon: [],
  //   keywords: ['chair'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666170966-icons8-chair-64.png',
  // },
  // {
  //   name: 'Competitionnumber',
  //   shortName: ['competition-number'],
  //   custom: true,
  //   text: ('competition-number'),
  //   emoticon: [],
  //   keywords: ['competition-number'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666170966-icons8-competition-64.png',
  // },
  // {
  //   name: 'Error',
  //   shortName: ['error'],
  //   custom: true,
  //   text: ('error'),
  //   emoticon: [],
  //   keywords: ['error'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666170966-icons8-error-64.png',
  // },
  // {
  //   name: 'Fans',
  //   shortName: ['fans'],
  //   custom: true,
  //   text: ('fans'),
  //   emoticon: [],
  //   keywords: ['fans'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666170966-icons8-fans-64.png',
  // },
  // {
  //   name: 'Foul',
  //   shortName: ['foul'],
  //   custom: true,
  //   text: ('foul'),
  //   emoticon: [],
  //   keywords: ['foul'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666170966-icons8-foul-64.png',
  // },
  // {
  //   name: 'Halloffame',
  //   shortName: ['hall-of-fame'],
  //   custom: true,
  //   text: ('hall-of-fame'),
  //   emoticon: [],
  //   keywords: ['hall', 'of', 'fame'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666170966-icons8-hall-of-fame-64.png',
  // },
  // {
  //   name: 'Medicine',
  //   shortName: ['medicine'],
  //   custom: true,
  //   text: ('medicine'),
  //   emoticon: [],
  //   keywords: ['medicine'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666171001-icons8-medicine-64.png',
  // },
  // {
  //   name: 'Scorebord',
  //   shortName: ['scorebord'],
  //   custom: true,
  //   text: ('scorebord'),
  //   emoticon: [],
  //   keywords: ['score', 'bord'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666171001-icons8-point-64-1.png',
  // },
  // {
  //   name: 'Referee',
  //   shortName: ['referee'],
  //   custom: true,
  //   text: ('referee'),
  //   emoticon: [],
  //   keywords: ['referee'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666171001-icons8-referee-64.png',
  // },
  // {
  //   name: 'Smash',
  //   shortName: ['smash'],
  //   custom: true,
  //   text: ('smash'),
  //   emoticon: [],
  //   keywords: ['smash'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666171002-icons8-smash-64.png',
  // },
  // {
  //   name: 'Whistle',
  //   shortName: ['whistle'],
  //   custom: true,
  //   text: ('whistle'),
  //   emoticon: [],
  //   keywords: ['whistle'],
  //   imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666171018-icons8-whistle-64.png',
  // }
]