export const customEmojisMLB: any = [
    
    // {
    //     name: 'Major',
    //     shortNames: ['major'],
    //     custom: true,
    //     text: '(major)',
    //     emoticons: [],
    //     keywords: ['major', 'league', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666085184-major.png',
    // },
    // {
    //     name: 'Mlbphotos',
    //     shortNames: ['mlb-photos'],
    //     custom: true,
    //     text: '(mlb-photos)',
    //     emoticons: [],
    //     keywords: ['mlb-photos'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666166722-29753-5-mlb-photos.png',
    // },
    // {
    //     name: 'Ball',
    //     shortNames: ['ball'],
    //     custom: true,
    //     text: '(ball)',
    //     emoticons: [],
    //     keywords: ['ball', 'gloves', 'catch', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750402-ball.png',
    // },
    // {
    //     name: 'Stopwatch',
    //     shortNames: ['stopwatch'],
    //     custom: true,
    //     text: '(stopwatch)',
    //     emoticons: [],
    //     keywords: ['ball', 'baseball', 'stopwatch'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750616-stopwatch.png',
    // },
    // {
    //     name: 'Baseballbat',
    //     shortNames: ['baseball-bat'],
    //     custom: true,
    //     text: '(baseball-bat)',
    //     emoticons: [],
    //     keywords: ['baseball-bat', 'baseballbat', 'bat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750833-bat.png',
    // },
    // {
    //     name: 'Baseballplayer',
    //     shortNames: ['baseball-player'],
    //     custom: true,
    //     text: '(baseball-player)',
    //     emoticons: [],
    //     keywords: ['baseball-player', 'baseballplayer', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751033-baseball.png',
    // },
    // {
    //     name: 'Bat',
    //     shortNames: ['bat'],
    //     custom: true,
    //     text: '(bat)',
    //     emoticons: [],
    //     keywords: ['baseballbat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751267-batt.png',
    // },
    // {
    //     name: 'Baseballcatch',
    //     shortNames: ['catch'],
    //     custom: true,
    //     text: '(catch)',
    //     emoticons: [],
    //     keywords: ['catch', 'player', 'baseballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751516-baseballcatch.png',
    // },
    // {
    //     name: 'Ballandbat',
    //     shortNames: ['ball-bat'],
    //     custom: true,
    //     text: '(ball-bat)',
    //     emoticons: [],
    //     keywords: ['baseball', 'base-ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751696-batball.png',
    // },
    // {
    //     name: 'Ballhit',
    //     shortNames: ['ball-hit'],
    //     custom: true,
    //     text: '(ball-hit)',
    //     emoticons: [],
    //     keywords: ['hit', 'hitball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751943-baseballhit.png',
    // },
    // {
    //     name: 'Throwingball',
    //     shortNames: ['throwing-ball'],
    //     custom: true,
    //     text: '(throwing-ball)',
    //     emoticons: [],
    //     keywords: ['throwingball', 'ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665752180-throwing.png',
    // },
    // {
    //     name: 'Competition',
    //     shortNames: ['competition'],
    //     custom: true,
    //     text: '(competition)',
    //     emoticons: [],
    //     keywords: ['competition', 'trophy', 'winner'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753450-match.png',
    // },
    // {
    //     name: 'Batcolor',
    //     shortNames: ['batcolor'],
    //     custom: true,
    //     text: '(batcolor)',
    //     emoticons: [],
    //     keywords: ['bats', 'bat', 'match'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753581-battball.png',
    // },
    // {
    //     name: 'Playerbat',
    //     shortNames: ['player-bat'],
    //     custom: true,
    //     text: '(player-bat)',
    //     emoticons: [],
    //     keywords: ['player-bat', 'player', 'bat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-50-1.png',
    // },
    // {
    //     name: 'Baseballicon',
    //     shortNames: ['baseball-icon'],
    //     custom: true,
    //     text: '(baseball-icon)',
    //     emoticons: [],
    //     keywords: ['baseball-icon', 'baseball', 'icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-50-2.png',
    // },
    // {
    //     name: 'Batball',
    //     shortNames: ['batball'],
    //     custom: true,
    //     text: '(batball)',
    //     emoticons: [],
    //     keywords: ['ball', 'batball', 'baseball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-60.png',
    // },
    // {
    //     name: 'Helmet',
    //     shortNames: ['helmet'],
    //     custom: true,
    //     text: '(helmet)',
    //     emoticons: [],
    //     keywords: ['ball', 'helmet', 'bat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-62.png',
    // },
    // {
    //     name: 'Glovesbatt',
    //     shortNames: ['gloves-batt'],
    //     custom: true,
    //     text: '(gloves-batt)',
    //     emoticons: [],
    //     keywords: ['ball', 'gloves', 'batt', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-64-1.png',
    // },
    // {
    //     name: 'Fantacy',
    //     shortNames: ['fantacy'],
    //     custom: true,
    //     text: '(fantacy)',
    //     emoticons: [],
    //     keywords: ['fantacy', 'icon'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-64-2.png',
    // },
    // {
    //     name: 'Audience',
    //     shortNames: ['audience'],
    //     custom: true,
    //     text: '(audience)',
    //     emoticons: [],
    //     keywords: ['Audience'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-64-3.png',
    // },
    // {
    //     name: 'Playerwithbatt',
    //     shortNames: ['player-with-batt'],
    //     custom: true,
    //     text: '(player-with-batt)',
    //     emoticons: [],
    //     keywords: ['player-with-batt', 'player', 'batt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-64-4.png',
    // },
    // {
    //     name: 'Ballyellow',
    //     shortNames: ['ball-yellow'],
    //     custom: true,
    //     text: '(ball-yellow)',
    //     emoticons: [],
    //     keywords: ['ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074885-icons8-baseball-64-5.png',
    // },
    // {
    //     name: 'Colorbat',
    //     shortNames: ['color-bat'],
    //     custom: true,
    //     text: '(color-bat)',
    //     emoticons: [],
    //     keywords: ['colorbat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-6.png',
    // },
    // {
    //     name: 'Mobile',
    //     shortNames: ['mobile'],
    //     custom: true,
    //     text: '(mobile)',
    //     emoticons: [],
    //     keywords: ['mobile'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-7.png',
    // },
    // {
    //     name: 'Star',
    //     shortNames: ['star'],
    //     custom: true,
    //     text: '(star)',
    //     emoticons: [],
    //     keywords: ['star'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-8.png',
    // },
    // {
    //     name: 'Location',
    //     shortNames: ['location'],
    //     custom: true,
    //     text: '(location)',
    //     emoticons: [],
    //     keywords: ['location'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-9.png',
    // },
    // {
    //     name: 'Gloves',
    //     shortNames: ['gloves'],
    //     custom: true,
    //     text: '(gloves)',
    //     emoticons: [],
    //     keywords: ['gloves', 'catch', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-10.png',
    // },
    // {
    //     name: 'Crossbat',
    //     shortNames: ['crossbat'],
    //     custom: true,
    //     text: '(crossbat)',
    //     emoticons: [],
    //     keywords: ['crossbat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-64-11.png',
    // },
    // {
    //     name: 'Handgloves',
    //     shortNames: ['handgloves'],
    //     custom: true,
    //     text: '(handgloves)',
    //     emoticons: [],
    //     keywords: ['handgloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074894-icons8-baseball-78.png',
    // },
    // {
    //     name: 'Colorballbat',
    //     shortNames: ['colorballbat'],
    //     custom: true,
    //     text: '(colorballbat)',
    //     emoticons: [],
    //     keywords: ['colorballbat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-100-1.png',
    // },
    // {
    //     name: 'Cloudball',
    //     shortNames: ['cloudball'],
    //     custom: true,
    //     text: '(cloudball)',
    //     emoticons: [],
    //     keywords: ['ball', 'cloudball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-100.png',
    // },
    // {
    //     name: 'Throwball',
    //     shortNames: ['throwball'],
    //     custom: true,
    //     text: '(throwball)',
    //     emoticons: [],
    //     keywords: ['ball', 'throwball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-ball-64-1.png',
    // },
    // {
    //     name: 'Flyingball',
    //     shortNames: ['flyingball'],
    //     custom: true,
    //     text: '(flyingball)',
    //     emoticons: [],
    //     keywords: ['flyingball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-ball-64.png',
    // },
    // {
    //     name: 'Twobat',
    //     shortNames: ['twobat'],
    //     custom: true,
    //     text: '(twobat)',
    //     emoticons: [],
    //     keywords: ['ball', 'twobat'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-bat-64-1.png',
    // },
    // {
    //     name: 'Enjoy',
    //     shortNames: ['enjoy'],
    //     custom: true,
    //     text: '(enjoy)',
    //     emoticons: [],
    //     keywords: ['enjoy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-bat-64.png',
    // },
    // {
    //     name: 'Cap',
    //     shortNames: ['cap'],
    //     custom: true,
    //     text: '(cap)',
    //     emoticons: [],
    //     keywords: ['cap'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-cap-100.png',
    // },
    // {
    //     name: 'Card',
    //     shortNames: ['card'],
    //     custom: true,
    //     text: '(card)',
    //     emoticons: [],
    //     keywords: ['card'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074904-icons8-baseball-card-64.png',
    // },
    // {
    //     name: 'Brownglove',
    //     shortNames: ['brown-glove'],
    //     custom: true,
    //     text: '(brown-glove)',
    //     emoticons: [],
    //     keywords: ['brown-glove', 'gloves', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074920-icons8-baseball-glove-64-1.png',
    // },
    // {
    //     name: 'Colorhand',
    //     shortNames: ['color-hand'],
    //     custom: true,
    //     text: '(color-hand)',
    //     emoticons: [],
    //     keywords: ['color-hand', 'gloves', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074920-icons8-baseball-glove-64.png',
    // },
    // {
    //     name: 'Playrglove',
    //     shortNames: ['playr-glove'],
    //     custom: true,
    //     text: '(playr-glove)',
    //     emoticons: [],
    //     keywords: ['playr-glove', 'gloves', 'catch', 'catching gloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074920-icons8-baseball-player-64-1.png',
    // },
    // {
    //     name: 'Heart',
    //     shortNames: ['heart'],
    //     custom: true,
    //     text: '(heart)',
    //     emoticons: [],
    //     keywords: ['heart'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074920-icons8-baseball-player-64-2.png',
    // },
    // {
    //     name: 'Watch',
    //     shortNames: ['watch'],
    //     custom: true,
    //     text: '(watch)',
    //     emoticons: [],
    //     keywords: ['watch'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074920-icons8-baseball-player-64-3.png',
    // },
    // {
    //     name: 'Ground',
    //     shortNames: ['ground'],
    //     custom: true,
    //     text: '(ground)',
    //     emoticons: [],
    //     keywords: ['ball', 'ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074921-icons8-baseball-stadium-64-2.png',
    // },
    // {
    //     name: 'Table',
    //     shortNames: ['table'],
    //     custom: true,
    //     text: '(table)',
    //     emoticons: [],
    //     keywords: ['table'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074921-icons8-baseball-stadium-64-3.png',
    // },
    // {
    //     name: 'Colorground',
    //     shortNames: ['color-ground'],
    //     custom: true,
    //     text: '(color-ground)',
    //     emoticons: [],
    //     keywords: ['color-ground', 'ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074921-icons8-baseball-stadium-64.png',
    // },
    // {
    //     name: 'Team',
    //     shortNames: ['team'],
    //     custom: true,
    //     text: '(team)',
    //     emoticons: [],
    //     keywords: ['team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-baseball-team-64.png',
    // },
    // {
    //     name: 'Batting',
    //     shortNames: ['batting'],
    //     custom: true,
    //     text: '(batting)',
    //     emoticons: [],
    //     keywords: ['batting'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-batting-64.png',
    // },
    // {
    //     name: 'Pinkcap',
    //     shortNames: ['pinkcap'],
    //     custom: true,
    //     text: '(pinkcap)',
    //     emoticons: [],
    //     keywords: ['pinkcap'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-cap-48.png',
    // },
    // {
    //     name: 'Bluecap',
    //     shortNames: ['bluecap'],
    //     custom: true,
    //     text: '(bluecap)',
    //     emoticons: [],
    //     keywords: ['bluecap'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-hip-hop-music-48.png',
    // },
    // {
    //     name: 'Jacket',
    //     shortNames: ['jacket'],
    //     custom: true,
    //     text: '(jacket)',
    //     emoticons: [],
    //     keywords: ['jacket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-jacket-64.png',
    // },
    // {
    //     name: 'Minor',
    //     shortNames: ['minor'],
    //     custom: true,
    //     text: '(minor)',
    //     emoticons: [],
    //     keywords: ['minor'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-league-64.png',
    // },
    // {
    //     name: 'Live',
    //     shortNames: ['live'],
    //     custom: true,
    //     text: '(live)',
    //     emoticons: [],
    //     keywords: ['live'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-live-64.png',
    // },
    // {
    //     name: 'Management',
    //     shortNames: ['management'],
    //     custom: true,
    //     text: '(management)',
    //     emoticons: [],
    //     keywords: ['management'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-management-48-1.png',
    // },
    // {
    //     name: 'Managementteam',
    //     shortNames: ['management-team'],
    //     custom: true,
    //     text: '(management-team)',
    //     emoticons: [],
    //     keywords: ['management-team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074930-icons8-management-48.png',
    // },
    // {
    //     name: 'Manager',
    //     shortNames: ['manager'],
    //     custom: true,
    //     text: '(manager)',
    //     emoticons: [],
    //     keywords: ['manager'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074941-icons8-manager-64.png',
    // },
    // {
    //     name: 'Sock',
    //     shortNames: ['sock'],
    //     custom: true,
    //     text: '(sock)',
    //     emoticons: [],
    //     keywords: ['sock'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074941-icons8-sock-64.png',
    // },
    // {
    //     name: 'Playerteam',
    //     shortNames: ['player-team'],
    //     custom: true,
    //     text: '(player-team)',
    //     emoticons: [],
    //     keywords: ['player-team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-team-64-1.png',
    // },
    // {
    //     name: 'Teamblue',
    //     shortNames: ['teamblue'],
    //     custom: true,
    //     text: '(teamblue)',
    //     emoticons: [],
    //     keywords: ['teamblue'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-team-64.png',
    // },
    // {
    //     name: 'Bench',
    //     shortNames: ['bench'],
    //     custom: true,
    //     text: '(bench)',
    //     emoticons: [],
    //     keywords: ['bench'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-team-bench-64.png',
    // },
    // {
    //     name: 'Ticket',
    //     shortNames: ['ticket'],
    //     custom: true,
    //     text: '(ticket)',
    //     emoticons: [],
    //     keywords: ['ticket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-ticket-64.png',
    // },
    // {
    //     name: 'Matchticket',
    //     shortNames: ['matchticket'],
    //     custom: true,
    //     text: '(matchticket)',
    //     emoticons: [],
    //     keywords: ['tickets', 'ticket', 'matchticket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-tickets-64.png',
    // },
    // {
    //     name: 'Uniform',
    //     shortNames: ['uniform'],
    //     custom: true,
    //     text: '(uniform)',
    //     emoticons: [],
    //     keywords: ['uniform'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666074942-icons8-uniform-64.png',
    // },
    // {
    //     name: 'Playing',
    //     shortNames: ['playing'],
    //     custom: true,
    //     text: '(playing)',
    //     emoticons: [],
    //     keywords: ['playing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666081802-baseball-2.png',
    // }
];