import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Observable, BehaviorSubject } from 'rxjs';
import {
  HttpClient
} from "@angular/common/http";
import { environment } from '../../../environments/environment';

import * as ios from 'socket.io-client'; //Socket(n)
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class UserConnectionService {

  //Connection/foolw/following check tab(n)
  private activeMainTabSource = new BehaviorSubject('');
  activeMainTabService = this.activeMainTabSource.asObservable();

  activeMainTab(activeMainTabMessage: string) {
    this.activeMainTabSource.next(activeMainTabMessage)
  }
  //End Connection/foolw/following check tab(n)

  //Socket(n)
  private url = environment.socket_base_url;//'http://localhost:3000';
  is_ssr: any;
  private socket;
  //Socket(n)
  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  constructor(private Http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    this.is_ssr = environment.is_ssr;
    if (this.is_ssr) {
      if (isPlatformBrowser(this.platformId)) {
        const io = require('socket.io-client');
        this.socket = io(environment.socket_base_url);//Socket(n)
      }
    } else {
      this.socket = ios(environment.socket_base_url);//Socket(n)
    }
  }

  //Socket(n)
  public sendRequestSocket(req) {
    this.socket.emit('socketApi', req);
  }

  public getRequestSocket = () => {
    return Observable.create((observer) => {
      this.socket.on('socketApi', (res) => {
        observer.next(res);
      });
    });
  }

  public getMessageSocket = () => {
    return Observable.create((observer) => {
      this.socket.on('socketMessageApi', (res) => {
        observer.next(res);
      });
    });
  }

  public getMessageSocket2(): Observable<any> {
    if (this.is_ssr) {
      return new Observable(observer => {
        if (this.socket) {
          this.socket.on('socketMessageApi', res => {
            observer.next(res);
          });
        }
      });
    } else {
      // const getMessageSocket2 = () => {
      return Observable.create((observer) => {
        this.socket.on('socketMessageApi', (res) => {
          observer.next(res);
        });
      });
      // }
    }
  }

  //Socket(n)


  getSearchUserListByName(Data) {
    return this.Http.post(`${environment.base_url}/getSearchUserListByName`, Data);
  }

  getSearchUserDetails(Data) {
    return this.Http.post(`${environment.base_url}/getSearchUserDetails`, Data);
  }

  viewallsports(Data) {
    return this.Http.post(`${environment.base_url}/viewallsports`, Data);
  }

  viewallachievements(Data) {
    return this.Http.post(`${environment.base_url}/viewallachievements`, Data);
  }

  viewallcoach(Data) {
    return this.Http.post(`${environment.base_url}/viewallcoach`, Data);
  }

  getFollowGroupUsersByUser(Data) {
    return this.Http.post(`${environment.base_url}/getFollowGroupUsersByUser`, Data);
  }

  getFollowUserProfileConnection(Data) {
    return this.Http.post(`${environment.base_url}/getFollowUserProfileConnection`, Data);
  }

  getFollowUserProfileFollowing(Data) {
    return this.Http.post(`${environment.base_url}/getFollowUserProfileFollowing`, Data);
  }

  getFollowUserProfileFollowers(Data) {
    return this.Http.post(`${environment.base_url}/getFollowUserProfileFollowers`, Data);
  }

  getFollowUserProfileMutualConnection(Data) {
    return this.Http.post(`${environment.base_url}/getFollowUserProfileMutualConnection`, Data);
  }

  getFollowUserProfileMutualFollowers(Data) {
    return this.Http.post(`${environment.base_url}/getFollowUserProfileMutualFollowers`, Data);
  }

  getFollowUserProfileMutualFollowing(Data) {
    return this.Http.post(`${environment.base_url}/getFollowUserProfileMutualFollowing`, Data);
  }

  getAliasFollowGroupUsersByUser(Data) {
    return this.Http.post(`${environment.base_url}/getAliasFollowGroupUsersByUser`, Data);
  }

  getSuggestedUserListByName(Data) {
    return this.Http.post(`${environment.base_url}/getSuggestedUserListByName`, Data);
  }

  userProfileStatus(Data) {
    return this.Http.post(`${environment.base_url}/userProfileStatus`, Data);
  }

  createUserProfileMute(Data) {
    return this.Http.post(`${environment.base_url}/createUserProfileMute`, Data);
  }

  unMuteUserProfile(Data) {
    return this.Http.post(`${environment.base_url}/unMuteUserProfile`, Data);
  }


  connectUserRequeset(Data) {
    return this.Http.post(`${environment.base_url}/connectUserRequeset`, Data);
  }

  followUserRequeset(Data) {
    return this.Http.post(`${environment.base_url}/followUserRequeset`, Data);
  }


  unFollowUser(Data) {
    return this.Http.post(`${environment.base_url}/unFollowUser`, Data);
  }

  disconnectUser(Data) {
    return this.Http.post(`${environment.base_url}/disconnectUser`, Data);
  }

  reportUser(Data) {
    return this.Http.post(`${environment.base_url}/reportUser`, Data);
  }

  getReportMessages(Data) {
    return this.Http.post(`${environment.base_url}/getReportMessages`, Data);
  }

  blockUser(Data) {
    return this.Http.post(`${environment.base_url}/blockUser`, Data);
  }

  getPostUserLikeList(Data) {
    return this.Http.post(`${environment.base_url}/getPostUserLikeList`, Data);
  }

  getPostUserShareList(Data) {
    return this.Http.post(`${environment.base_url}/getPostUserShareList`, Data);
  }

  readNotification(Data) {
    return this.Http.post(`${environment.base_url}/readNotification`, Data);
  }

  markAllAsReadNotification(Data) {
    return this.Http.post(`${environment.base_url}/markAllAsReadNotification`, Data);
  }
  pendingRequest(Data) {
    return this.Http.post(`${environment.base_url}/pendingRequest`, Data);
  }
  markAllAsReadMessage(data) {
    return this.Http.get(`${environment.base_url}/markAllAsReadMessage`, data);
  }

  getCommentLikesUserList(data) {
    return this.Http.post(`${environment.base_url}/getCommentLikesUserList`, data);
  }

  getCommentReplyLikesUserList(data) {
    return this.Http.post(`${environment.base_url}/getCommentReplyLikesUserList`, data);
  }

  postReportEmail(Data) {
    return this.Http.post(`${environment.social_media_url}/postReportEmail`, Data);
  }

  userReportEmail(Data) {
    return this.Http.post(`${environment.social_media_url}/userReportEmail`, Data);
  }

  connectionRequestEmail(Data) {
    return this.Http.post(`${environment.social_media_url}/connectionRequestEmail`, Data);
  }

  connectionAcceptEmail(Data) {
    return this.Http.post(`${environment.social_media_url}/connectionAcceptEmail`, Data);
  }

  followRequestEmail(Data) {
    if (Data && Data.to_user_details && Data.to_user_details[0] && Data.to_user_details[0].platform_id != environment.platform_id) {
      return this.Http.post(`${environment.other_social_media_url}/followRequestEmail`, Data);
    } else {
      return this.Http.post(`${environment.social_media_url}/followRequestEmail`, Data);
    }

  }

  getPendingFollowersByUser(Data) {
    return this.Http.post(`${environment.base_url}/getPendingFollowersByUser`, Data);
  }

  getAliasPendingFollowersByUser(Data) {
    return this.Http.post(`${environment.base_url}/getAliasPendingFollowersByUser`, Data);
  }

  getAdvertisementListUser(Data) {
    return this.Http.post(`${environment.base_url}/getAdvertisementListUser`, Data);
  }

  getFollowersListPostTimeline(Data) {
    return this.Http.post(`${environment.base_url}/getFollowersListPostTimeline`, Data);
  }

  getPostMediaUserLikeList(Data) {
    return this.Http.post(`${environment.base_url}/getPostMediaUserLikeList`, Data);
  }

  getMediaCommentLikesUserList(Data) {
    return this.Http.post(`${environment.base_url}/getMediaCommentLikesUserList`, Data);
  }

  getMediaCommentReplyLikesUserList(Data) {
    return this.Http.post(`${environment.base_url}/getMediaCommentReplyLikesUserList`, Data);
  }

  pagesFollowed(Data) {
    return this.Http.post(`${environment.base_url}/pagesFollowed`, Data);
  }

  newsCategoriesFollowed() {
    return this.Http.get(`${environment.base_url}/newsCategoriesFollowed`);
  }

  showAllTeams(data) {
    return this.Http.post(`${environment.base_url}/showAllTeams`, data);
  }

  checkUserExist(data) {
    return this.Http.post(`${environment.base_url}/checkUserExist`, data);
  }

  sendPushNotification(data) {
    return this.Http.post(`${environment.base_url}/sendPushNotification`, data).subscribe();
  }

}
