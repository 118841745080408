import { Component, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Component({
	selector: 'app-outer-header-navigation',
	templateUrl: './outer-header-navigation.component.html',
	styleUrls: ['./outer-header-navigation.component.css']
})
export class OuterHeaderNavigationComponent implements OnInit {
	isLoggedIn: boolean = false;
	platform_logo: string;
	help_center = environment.help_center;
	activeUrl: string;
	loadCssAPI: Promise<any>;

	constructor(
		private route: Router,
	) {
		this.platform_logo = environment.platform_logo;
		this.activeUrl = this.route.url;
		if (localStorage.getItem('token')) {
			this.isLoggedIn = true;
		}

		this.loadCssAPI = new Promise((resolve) => {
			this.addandLoadCss();
			resolve(true);
		});
	}

	public loadScript() {
		let isFound = false;
		let scripts = document.getElementsByTagName("script")
		for (let i = 0; i < scripts.length; ++i) {
			if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('class') && scripts[i].getAttribute('class').includes("itsgametimeJs")) {
				isFound = true;
			}
		}

		if (!isFound) {
			let dynamicScripts = [

				"../../assets/landing-js/its-game-time.js",
			];

			for (let i = 0; i < dynamicScripts.length; i++) {
				let node = document.createElement('script');
				node.src = dynamicScripts[i];
				node.type = 'text/javascript';
				node.async = false;
				node.charset = 'utf-8';
				document.getElementsByTagName('body')[0].appendChild(node);
			}

		}
	}

	public addandLoadCss() {
		let isFoundCss = false;
		let linkArray = document.getElementsByTagName("link");
		for (let i = 0; i < linkArray.length; i++) {
			if (linkArray[i].getAttribute('href') != null && linkArray[i].getAttribute('id') && linkArray[i].getAttribute('id').includes("itsGameTimeCss")) {
				isFoundCss = true;
			}
		}

		if (!isFoundCss) {
			let dynamicCss = [
				"../../assets/landing-css/normalize.css",
				"../../assets/landing-css/components.css",
				"../../assets/landing-css/its-game-time.css",
			];

			for (let i = 0; i < dynamicCss.length; i++) {
				let link = document.createElement('link');
				link.rel = "stylesheet";
				link.type = 'text/css';
				link.id = "itsGameTimeCss" + i;
				link.href = dynamicCss[i];
				document.head.appendChild(link);
			}
		}
		this.loadScript();
	}

	ngOnInit() { }

	logout() {
		localStorage.clear();
		sessionStorage.removeItem('fullcomponent');
		this.route.navigate(['/login']);
	}

	removejscssfile(filename, filetype) {
		let targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none" //determine element type to create nodelist from
		let targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none" //determine corresponding attribute to test for
		let allsuspects = document.getElementsByTagName(targetelement)
		for (let i = allsuspects.length; i >= 0; i--) { //search backwards within nodelist for matching elements to remove
			if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1)
				allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
		}
	}

	ngAfterViewInit() {
		scrollTo(10, 10);
		setTimeout(() => {
		  scrollTo(0, 0);
		}, 1000);
	  }
	  
	ngOnDestroy() {
		this.removejscssfile("its-game-time.js", "js");
		this.removejscssfile("normalize.css", "css");
		this.removejscssfile("components.css", "css");
		this.removejscssfile("its-game-time.css", "css");
	}

}