import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genderTransform'
})
export class GenderTransformPipe implements PipeTransform {
  transform(gender: string): string {
    if (gender === 'male' || gender === "Male" || gender === "MALE" ) {
      return 'Men';
    } else if (gender === 'female' || gender === 'Female' || gender === "FEMALE") {
      return 'Women';
    } else {
      return gender; 
    }
  }
}