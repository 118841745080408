import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'checkHtmlValidity'
})
export class CheckHtmlValidityPipe implements PipeTransform {

	constructor(private sanitized: DomSanitizer) { }

	transform(value: string): any {
		const completeHtml = /<([a-z][^/>\s]*)([^>]*?)>/gi;
		const sanitizedValue = this.sanitized.bypassSecurityTrustHtml(value);
		if (completeHtml.test(sanitizedValue as string)) {
			return true;
		} else {
			return false;
		}
	}
}
