import { Injectable,PLATFORM_ID,Inject } from '@angular/core';
import {isPlatformServer } from '@angular/common'; 
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { SigninSignUpService } from '../UsersSignInSignUp/signin-sign-up.service';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  deviceType = environment.device_type;
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private _SigninSignUp: SigninSignUpService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformServer(this.platformId)) {
      // This code runs on the server during SSR.
    } else {
     
      this.angularFireMessaging.messaging.subscribe((_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.getToken = _messaging.getToken.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      });
    }
  }
  requestPermission(type) {
    if (!isPlatformServer(this.platformId)) {
      // This code runs on the server during SSR.
      this.angularFireMessaging.requestToken.subscribe(
        (token) => {
          if (type == 'add') {
            this._SigninSignUp.userAddNotificationToken({ token: token, device_type: this.deviceType }).subscribe(res => {
              if (res && res['message']) {
                // console.log(res['message']);
              }
            });
          } else {
            this._SigninSignUp.updateUserIdByNotiToken({ token: token, device_type: this.deviceType }).subscribe(res => {
              if (res && res['message']) {
                // console.log(res['message']);
              }
            });
          }
          return { token: token };
        },
        (err) => {
          console.error('Unable to get permission to notify.', err);
        }
      );
    } 
  }
  receiveMessage() {
    if (isPlatformServer(this.platformId)) {
      // This code runs on the server during SSR.
    } else {
      this.angularFireMessaging.messages.subscribe(
        (payload) => {
          // console.log("new message received. ", payload);
          this.currentMessage.next(payload);
        })
      }
  }
}
