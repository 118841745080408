export const customEmojisMLS: any = [
    // {
    //     name: 'Stadium',
    //     shortNames: ['stadium'],
    //     custom: true,
    //     text: '(Stadium)',
    //     emoticons: [],
    //     keywords: ['stadium', 'ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665744832-stadium-1.png',
    // },
    // {
    //     name: 'Kick',
    //     shortNames: ['kick'],
    //     custom: true,
    //     text: '(kick)',
    //     emoticons: [],
    //     keywords: ['kick', 'penalty', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665745505-penalty-kick-1.png',
    // },
    // {
    //     name: 'Kickball',
    //     shortNames: ['kickball'],
    //     custom: true,
    //     text: '(kickball)',
    //     emoticons: [],
    //     keywords: ['kickball', 'football', 'player', 'attamptfootball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665746267-soccer-1.png',
    // },
   
    // {
    //     name: 'footballrun',
    //     shortNames: ['football-run'],
    //     custom: true,
    //     text: '(football-run)',
    //     emoticons: [],
    //     keywords: ['football', 'kick', 'sportsshoes', 'colouredfootball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665746899-football-player-1.png',
    // },
    // {
    //     name: 'Footballfield',
    //     shortNames: ['football-field'],
    //     custom: true,
    //     text: '(football-field)',
    //     emoticons: [],
    //     keywords: ['footballfield', 'ground', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747231-football-field.png',
    // },
    // {
    //     name: 'Player',
    //     shortNames: ['player'],
    //     custom: true,
    //     text: '(player)',
    //     emoticons: [],
    //     keywords: ['player', 'football', 'american', 'jersey', 'helmet'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747523-american-football-player-1.png',
    // },
    // {
    //     name: 'Americanfootballfield',
    //     shortNames: ['american-football-field'],
    //     custom: true,
    //     text: '(american-football-field)',
    //     emoticons: [],
    //     keywords: ['americanfootballfield', 'footballfield'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747713-american-football-field-1.png',
    // },
    // {
    //     name: 'Supporter',
    //     shortNames: ['supporter'],
    //     custom: true,
    //     text: '(supporter)',
    //     emoticons: [],
    //     keywords: ['supporter', 'fans'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665747895-supporter-1.png',
    // },
    // {
    //     name: 'Cup',
    //     shortNames: ['cup'],
    //     custom: true,
    //     text: '(cup)',
    //     emoticons: [],
    //     keywords: ['trophy', 'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665748073-world-cup.png',
    // },
    // {
    //     name: 'Ground',
    //     shortNames: ['Ground'],
    //     custom: true,
    //     text: '(Ground)',
    //     emoticons: [],
    //     keywords: ['Ground', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988968-american-football-field.png',
    // },
    // {
    //     name: 'Americanplayer',
    //     shortNames: ['american-player'],
    //     custom: true,
    //     text: '(american-player)',
    //     emoticons: [],
    //     keywords: ['americanplayer', 'player', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988969-american-football-player-2---Copy.png',
    // },
    // {
    //     name: 'Systemlive',
    //     shortNames: ['system-live'],
    //     custom: true,
    //     text: '(system-live)',
    //     emoticons: [],
    //     keywords: ['systemlive', 'match', 'game'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988969-football-2---Copy.png',
    // },
    // {
    //     name: 'Moveball',
    //     shortNames: ['Move-ball'],
    //     custom: true,
    //     text: '(Move-ball)',
    //     emoticons: [],
    //     keywords: ['Moveball', 'football', 'flying', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988969-football-3---Copy.png',
    // },
    // {
    //     name: 'Award',
    //     shortNames: ['award'],
    //     custom: true,
    //     text: '(award)',
    //     emoticons: [],
    //     keywords: ['award', 'trophy', 'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988969-football-award.png',
    // },
    // {
    //     name: 'Womenplayer',
    //     shortNames: ['women-player'],
    //     custom: true,
    //     text: '(women-player)',
    //     emoticons: [],
    //     keywords: ['womenplayer', 'player', 'footballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988978-football-player-5---Copy.png',
    // },
    // {
    //     name: 'players',
    //     shortNames: ['players'],
    //     custom: true,
    //     text: '(players)',
    //     emoticons: [],
    //     keywords: ['players', 'player', 'footballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988979-football-players.png',
    // },
    // {
    //     name: 'Friendship',
    //     shortNames: ['friendship'],
    //     custom: true,
    //     text: '(friendship)',
    //     emoticons: [],
    //     keywords: ['players', 'friendship', 'footballplayer', 'handshake'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988979-friendship.png',
    // },
    // {
    //     name: 'Gloves',
    //     shortNames: ['gloves'],
    //     custom: true,
    //     text: '(gloves)',
    //     emoticons: [],
    //     keywords: ['gloves', 'playergloves'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988979-gloves.png',
    // },
    // {
    //     name: 'Goal',
    //     shortNames: ['goal'],
    //     custom: true,
    //     text: '(goal)',
    //     emoticons: [],
    //     keywords: ['goal', 'kick', 'footballgoal'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988979-goal---Copy-2.png',
    // },
    // {
    //     name: 'Livefootball',
    //     shortNames: ['Live-football'],
    //     custom: true,
    //     text: '(Live-football)',
    //     emoticons: [],
    //     keywords: ['livefootball', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989019-live.png',
    // },
    // {
    //     name: 'Match',
    //     shortNames: ['match'],
    //     custom: true,
    //     text: '(match)',
    //     emoticons: [],
    //     keywords: ['match', , 'footballmatch'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989019-match.png',
    // },
    // {
    //     name: 'Medal',
    //     shortNames: ['medal'],
    //     custom: true,
    //     text: '(medal)',
    //     emoticons: [],
    //     keywords: ['medal', 'award'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989019-medal.png',
    // },
    // {
    //     name: 'Headshots',
    //     shortNames: ['headshot'],
    //     custom: true,
    //     text: '(headshot)',
    //     emoticons: [],
    //     keywords: ['goal', 'headshot', 'football', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989019-play---Copy.png',
    // },
    // {
    //     name: 'Playing',
    //     shortNames: ['playing'],
    //     custom: true,
    //     text: '(playing)',
    //     emoticons: [],
    //     keywords: ['goal', 'kick', 'playing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989019-playing---Copy.png',
    // },
    // {
    //     name: 'Confussed',
    //     shortNames: ['confussed'],
    //     custom: true,
    //     text: '(confussed)',
    //     emoticons: [],
    //     keywords: ['goal', 'kick', 'confussed', 'question'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989037-question---Copy---Copy.png',
    // },
    // {
    //     name: 'Referee',
    //     shortNames: ['referee'],
    //     custom: true,
    //     text: '(referee)',
    //     emoticons: [],
    //     keywords: ['goal', 'panelty', 'referee'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989037-referee---Copy---Copy.png',
    // },
    // {
    //     name: 'Scout',
    //     shortNames: ['scout'],
    //     custom: true,
    //     text: '(scout)',
    //     emoticons: [],
    //     keywords: ['scout', 'football', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989037-scout.png',
    // },
    // {
    //     name: 'Kickfootball',
    //     shortNames: ['kick-football'],
    //     custom: true,
    //     text: '(kick-football)',
    //     emoticons: [],
    //     keywords: ['kick', 'kickfootball', 'football', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989037-shoot.png',
    // },
    // {
    //     name: 'Chestshot',
    //     shortNames: ['chestshot'],
    //     custom: true,
    //     text: '(chestshot)',
    //     emoticons: [],
    //     keywords: ['chestshot', 'football', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989038-soccer-1.png',
    // },
    // {
    //     name: 'Footballnet',
    //     shortNames: ['football-net'],
    //     custom: true,
    //     text: '(football-net)',
    //     emoticons: [],
    //     keywords: ['goal', 'footballnet', 'football', 'net'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989038-soccer-2---Copy.png',
    // },
    // {
    //     name: 'Jersey',
    //     shortNames: ['jersey'],
    //     custom: true,
    //     text: '(jersey)',
    //     emoticons: [],
    //     keywords: ['jersey', 'footballjersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989070-soccer-jersey.png',
    // },

    // {
    //     name: 'Sportsman',
    //     shortNames: ['sportsman'],
    //     custom: true,
    //     text: '(sportsman)',
    //     emoticons: [],
    //     keywords: ['playin-football', 'football', 'player', 'sportsman'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989070-sportsman.png',
    // },
    // {
    //     name: 'Timer',
    //     shortNames: ['timer'],
    //     custom: true,
    //     text: '(timer)',
    //     emoticons: [],
    //     keywords: ['timer', 'footballgame'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989071-timer.png',
    // },
    // {
    //     name: 'Team',
    //     shortNames: ['team'],
    //     custom: true,
    //     text: '(team)',
    //     emoticons: [],
    //     keywords: ['playinfootball', 'football', 'player', 'team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989071-team.png',
    // },
    // {
    //     name: 'Versus',
    //     shortNames: ['versus'],
    //     custom: true,
    //     text: '(versus)',
    //     emoticons: [],
    //     keywords: ['versus', 'footballmatch', 'helmet'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989080-vs---Copy.png',
    // },
    // {
    //     name: 'Americanstadium',
    //     shortNames: ['American-stadium'],
    //     custom: true,
    //     text: '(American-stadium)',
    //     emoticons: [],
    //     keywords: ['Americanstadium', 'stadium', 'ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989071-stadium.png',
    // },
    // {
    //     name: 'Kickshot',
    //     shortNames: ['kick-shot'],
    //     custom: true,
    //     text: '(kick-shot)',
    //     emoticons: [],
    //     keywords: ['playinfootball', 'football', 'kickshot'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989070-soccer-player---Copy.png',
    // },
    // {
    //     name: 'Trophy',
    //     shortNames: ['trophy'],
    //     custom: true,
    //     text: '(trophy)',
    //     emoticons: [],
    //     keywords: ['trophy', 'football', 'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989071-trophy-2---Copy.png',
    // },
    // {
    //     name: 'Worldcup',
    //     shortNames: ['world-cup'],
    //     custom: true,
    //     text: '(world-cup)',
    //     emoticons: [],
    //     keywords: ['worldcup', 'football', 'trophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665989080-world-cup.png',
    // },
    // {
    //     name: 'Ticket',
    //     shortNames: ['ticket'],
    //     custom: true,
    //     text: '(ticket)',
    //     emoticons: [],
    //     keywords: ['ticket', 'footballmatchticket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665988562-ticket.png',
    // },
    // {
    //     name: 'Vest',
    //     shortNames: ['vest'],
    //     custom: true,
    //     text: '(vest)',
    //     emoticons: [],
    //     keywords: ['vest', 'football', 'player', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750933-vest-1.png',
    // },
    // {
    //     name: 'Barcelona',
    //     shortNames: ['barcelona'],
    //     custom: true,
    //     text: '(barcelona)',
    //     emoticons: [],
    //     keywords: ['barcelona', 'footballteam', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666014495-barcelona.png',
    // },
    // {
    //     name: 'Coach',
    //     shortNames: ['coach'],
    //     custom: true,
    //     text: '(coach)',
    //     emoticons: [],
    //     keywords: ['coach', 'footballcoach'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666014495-coach.png',
    // },
    // {
    //     name: 'Competition',
    //     shortNames: ['competition'],
    //     custom: true,
    //     text: '(competition)',
    //     emoticons: [],
    //     keywords: ['competition', 'trophy', 'versus'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666014495-competition.png',
    // },
    // {
    //     name: 'Lineup',
    //     shortNames: ['lineup'],
    //     custom: true,
    //     text: '(lineup)',
    //     emoticons: [],
    //     keywords: ['playinfootball', 'lineup', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666014495-lineup.png',
    // },
    // {
    //     name: 'Pitch',
    //     shortNames: ['pitch'],
    //     custom: true,
    //     text: '(pitch)',
    //     emoticons: [],
    //     keywords: ['pitch', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666014495-pitch.png',
    // },
    // {
    //     name: 'Fans',
    //     shortNames: ['fans'],
    //     custom: true,
    //     text: '(fans)',
    //     emoticons: [],
    //     keywords: ['fans', 'supporter'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666015443-fans.png',
    // },
    // {
    //     name: 'Goalonnet',
    //     shortNames: ['goal-on-net'],
    //     custom: true,
    //     text: '(goalonnet)',
    //     emoticons: [],
    //     keywords: ['goalonnet', 'football', 'net'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666015443-goalonnet.png',
    // },
    // {
    //     name: 'Scoreboard',
    //     shortNames: ['scoreboard'],
    //     custom: true,
    //     text: '(scoreboard)',
    //     emoticons: [],
    //     keywords: ['scoreboard', 'timer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666015443-scoreboard.png',
    // },
    // {
    //     name: 'Watching',
    //     shortNames: ['watching'],
    //     custom: true,
    //     text: '(watching)',
    //     emoticons: [],
    //     keywords: ['watching', 'football', 'livefootball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666015443-watching.png',
    // },
    // {
    //     name: 'Soccer',
    //     shortNames: ['soccer'],
    //     custom: true,
    //     text: '(soccer)',
    //     emoticons: [],
    //     keywords: ['soccer', 'player', 'match'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665755482-football-ball.png',
    // },
    // {
    //     name: 'KickFootballplayer',
    //     shortNames: ['kick-Football-player'],
    //     custom: true,
    //     text: '(kick-Football-player)',
    //     emoticons: [],
    //     keywords: ['soccer', 'player', 'kickFootballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665755615-football-player.png',
    // },
    // {
    //     name: 'Fifaworldcup',
    //     shortNames: ['Fifa-worldcup'],
    //     custom: true,
    //     text: '(Fifa-worldcup)',
    //     emoticons: [],
    //     keywords: ['Fifaworldcup', 'worldcup', 'trophy'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665755810-cup.png',
    // },
    // {
    //     name: 'Soccerball',
    //     shortNames: ['soccer-ball'],
    //     custom: true,
    //     text: '(soccer-ball)',
    //     emoticons: [],
    //     keywords: ['soccer-ball', 'soccerball', 'soccer-baoll'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665818629-goal.png',
    // },
    // {
    //     name: 'Footballplayer',
    //     shortNames: ['foot-ball-player'],
    //     custom: true,
    //     text: '(foot-ball-player)',
    //     emoticons: [],
    //     keywords: ['soccer', 'player', 'footballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665816950-soccerball.png',
    // },
    // {
    //     name: 'Football',
    //     shortNames: ['football'],
    //     custom: true,
    //     text: '(football)',
    //     emoticons: [],
    //     keywords: ['soccer', 'player', 'football'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665817537-football.png',
    // },
    // {
    //     name: 'Soccerkick',
    //     shortNames: ['Soccerkick'],
    //     custom: true,
    //     text: '(Soccerkick)',
    //     emoticons: [],
    //     keywords: ['soccer', 'players', 'football_player', 'kick'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665817968-kick.png',
    // },
    // {
    //     name: 'Netsoccerball',
    //     shortNames: ['Netsoccerball'],
    //     custom: true,
    //     text: '(Netsoccerball)',
    //     emoticons: [],
    //     keywords: ['soccer', 'Netsoccerball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665818238-goal.png',
    // },
    // {
    //     name: 'Ballonfire',
    //     shortNames: ['ball-on-fire'],
    //     custom: true,
    //     text: '(ball-on-fire)',
    //     emoticons: [],
    //     keywords: ['ball', 'fireball', 'ballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665816666-soccer-ball.png',
    // },
    // {
    //     name: 'Playfootball',
    //     shortNames: ['play-football'],
    //     custom: true,
    //     text: '(play-football)',
    //     emoticons: [],
    //     keywords: ['soccerball', 'ball', 'soccerballkicked', 'Playootball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665819254-football-players.png',
    // },
    // {
    //     name: 'Mlslogo',
    //     shortNames: ['mls-logo'],
    //     custom: true,
    //     text: '(mls-logo)',
    //     emoticons: [],
    //     keywords: ['soccer', 'mls-logo', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666203846-mls-logo.png',
    // },
    // {
    //     name: 'Mls',
    //     shortNames: ['mls'],
    //     custom: true,
    //     text: '(mls)',
    //     emoticons: [],
    //     keywords: ['mls', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666203846-mls-main-logo.png',
    // },
    // {
    //     name: 'Timber',
    //     shortNames: ['timber'],
    //     custom: true,
    //     text: '(timber)',
    //     emoticons: [],
    //     keywords: ['timber', 'team'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666203847-timber.jfif',
    // },
    // {
    //     name: 'Mlsdesignlogo',
    //     shortNames: ['mls-design-logo'],
    //     custom: true,
    //     text: '(mls-design-logo)',
    //     emoticons: [],
    //     keywords: ['mlsdesignlogo', 'logo', 'mls'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666203846-mls-design-logo.jfif',
    // },
    // {
    //     name: 'Hustondiamonds',
    //     shortNames: ['huston-diamonds'],
    //     custom: true,
    //     text: '(huston-diamonds)',
    //     emoticons: [],
    //     keywords: ['hustondiamonds', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666203846-huston-diamonds.png',
    // } 
];
