export const environment = {
  production: true,
  //*****Patriotpals*****// 
  platform_name: "Itsgametime",
  other_platform_name: "Itsgametimes",
  platform_logo: "assets/images/logo.png",
  platform_logo_dark: 'assets/images/logo_dark.png',
  meta_logo: "assets/images/metalogo.jpg",
  news_seo: 'assets/images/news_seo.jpg',
  contact_seo: 'assets/images/contact_seo.jpg',
  shortcut_seo: 'assets/images/shortcut_seo.jpg',
  index_seo: 'assets/images/index_seo.jpg',
  landing_seo: 'assets/images/landing_seo.jpg',
  login_seo: 'assets/images/login_seo.jpg',
  login_meta: "assets/images/patriot_login_meta.jpg",
  home_meta: "assets/images/patriot_home_meta.jpg",
  other_platform_logo: "assets/images/logo_progressive.png",
  terms_and_condition_logo: 'assets/images/Terms_and_Conditions_SEO_Image.jpeg',
  privacy_policy_logo: 'assets/images/Privacy_Policy_SEO_Image_(1).jpeg',

  team_player_time_diff: 48,
  device_type: "web", //web / android / ios
  is_ssr: true, // true for ssr build (web), false for (android/ios)

  // widget_url: "http://localhost/",
  widget_url: "https://staging.itsgametime.com/",

  project_url: "https://staging.itsgametime.com/",
  admin_url: "https://staging.itsgametime.com/admin1987",
  base_url: "https://stagingapi.itsgametime.com/api",            // itsgametime live server API
  gs_widget_url: 'https://stagingapi.itsgametime.com/widget/',
  ipl_widget_url: 'https://stagingapi.itsgametime.com/widget/ipl_team_roster.php',
  siteMap_link: 'https://staging.itsgametime.com/sitemap.xml',
  news_site_map: 'https://staging.itsgametime.com/newssitemap',


  added_leagues: ['IPL', 'ICC T20 World Cup'],
  gs_leagues: ['NCAA Football', 'NCAA Basketball'],
  not_gs_leagues:['NFL','MLB','NHL','NBA','MLS','Tennis','Golf','IPL','ICC T20 World Cup'],

  all_desc: "Experience the excitement of professional sports with live scores, breaking news, and in-depth analysis from the world's top leagues. Follow your favorite teams and athletes as they compete for glory on the biggest stages in baseball, football, cricket, golf, soccer, basketball, hockey, and tennis.",
  mlb_desc: "Stay updated with the latest MLB news, scores, stats, and highlights. Follow your favorite teams and players throughout the season.",
  nfl_desc: "Get the latest NFL scores, news, and analysis. Follow your favorite teams and players as they compete for glory on the gridiron.",
  ipl_desc: "Catch all the action of the IPL with live scores, match updates, and analysis. Stay tuned for thrilling cricket encounters featuring the world's top players.",
  golf_desc: "Follow the world of golf with the latest news, tournament updates, and player rankings. Stay on par with the PGA Tour, LPGA Tour, and more.",
  mls_desc: "Follow the world of golf with the latest news, tournament updates, and player rankings. Stay on par with the PGA Tour, LPGA Tour, and more.",
  nba_desc: "Keep up with the NBA season with live scores, game highlights, and player stats. Follow your favorite teams and stars as they hoop it up on the hardwood.",
  ncaaf_desc: "Dive into NCAA football with game recaps, rankings, and analysis. Follow the excitement of college football as teams clash for gridiron supremacy.",
  ncaab_desc: "Experience the thrill of NCAA basketball with live scores, bracket updates, and player highlights. Follow March Madness and the road to the Final Four.",
  nhl_desc: "Get your NHL fix with scores, news, and player updates. Follow the puck as teams compete for the Stanley Cup in the world's premier ice hockey league.",
  tennis_desc: "Stay informed on the world of tennis with match results, player rankings, and tournament coverage. Follow the ATP, WTA, and Grand Slam events for all the action on the court.",

  // widget color values start 
  widget_values: {
    game_info_bg_value: '#e6e6e6',
    game_info_color: '#000',
    font_family: '"Roboto", sans-serif !important',
    title_bg: '#c0d4ee',
    title_color: '#2d368e',
    sub_title_bg: '#e6e6e6',
    sub_title_color: '#000',
    team_ranked_stats_title_color: '#38a5ce',
    team_player_stats_h3_headings_bg: '#e3e3e7',
    team_player_stats_h3_headings_color: '#8c8e96',

    player_key_stats_heading_bg: '#c0d4ee',
    player_key_stats_heading_color: '#2d368e',
    player_key_stats_data_bg : '#f2f2f2',
    
    player_game_log_heading_bg: '#38a5ce',
    player_game_log_heading_color: '#fff',

    player_career_stats_heading_bg: '#c0d4ee',
    player_career_stats_heading_color: '#2d368e'
  },
  // widget color values end

  //shortcuts to pages
  help_center: 'https://itsgametime.zendesk.com/hc/en-us',

  android_web_shortcut: 'https://staging.itsgametime.com/shortcut-app',
  playStoreAppShortcut: "https://play.google.com/store/apps/details?id=com.itsgame.time",
  appleStoreAppShortcut: "https://apps.apple.com/in/app/itsgametime/id6451241875",
  terms_and_condition: 'https://staging.itsgametime.com/terms-of-use',
  privacy_policy: 'https://staging.itsgametime.com/privacy-policy',

  // android_web_shortcut: 'http://localhost:4200/shortcut-app',
  // terms_and_condition: 'http://localhost:4200/terms-of-use',
  // privacy_policy: 'http://localhost:4200/privacy-policy',


  socket_base_url: "https://api.itsgametime.com:4000",
  social_media_url: "https://api.itsgametime.com:3000",     // Social media login base url
  other_social_media_url: "https://api.itsgametime.com:3500",      //Social media login base url
  user_profile_img: "assets/images/users/default-user.png",
  user_cover_img: "assets/images/background/login-register-black-1.jpg",
  platform_id: "1",
  alias_mode_name: "Stealth Mode",  // Alias Mode name in Open Forum.
  alias_user_name: "Stealth",  // Alias user name in Open Forum.
  alias_lower_case_user_name: "stealth",  // Alias user name in Open Forum.
  svg: "svg_patriot", // making the svg icons dynamics for patriot pals
  comment_icon: "assets/images/patriot_icon/comment.svg",
  share_icon: "assets/images/patriot_icon/share.svg",
  tagTeam_icon: "assets/images/patriot_icon/tagTeam.svg",
  connection_icon: "assets/images/icon/Connections.svg",
  following_icon: "assets/images/icon/Following_new.svg",
  follower_icon: "assets/images/icon/Fans-ICON.svg",
  // connection_icon: "assets/images/Connections.svg",
  // following_icon: "assets/images/Following.svg",
  // follower_icon: "assets/images/Followers.svg",
  like_icon: "assets/images/patriot_icon/like.svg",
  like_outline_icon: "assets/images/patriot_icon/like_outline.svg",
  shout_icon: "assets/images/patriot_icon/shout.svg",
  pals_bg: " linear-gradient( rgba(255, 255, 255, 0.5) 100%, rgba(255, 255, 255, 0.5)100%), url('assets/images/pals-bg.jpg')",
  // open_forum_bg: "url('assets/images/openforumprimary.jpg')",
  open_forum_alias_bg: "url('assets/images/open-forum-alias-bg.jpg')",
  faq_path: "assets/images/faq-patriot/",
  faq_name: 'Itsgametime',//"Patriot Pals",
  blog_img: "assets/images/patriot_blog.jpeg",
  homeContent: 'It’s Game Time is a sports social media community designed to bring fellow sports fans from around the world together to stay updated, share, and get involved with the current world of sports. Stay up to date with our live and past scores, watch the latest sports videos as they happen, and discuss your favourite sports with other fans. Follow players and teams and connect with others that share the same interests. We hope you enjoy It’s Game Time and please spread the word to your fellow sports enthusiasts!',
  popup_content_singular: 'Conservative',
  popup_content: 'conservatives',
  other_popup_content: 'itsgametime',//'progressives',
  iphone_web_shortcut: '#',//'https://patriotpals.zendesk.com/hc/en-us/articles/360052764233-Creating-a-shortcut-for-your-iPhone',
  ipad_web_shortcut: '#',//'https://patriotpals.zendesk.com/hc/en-us/articles/360052765133-Create-a-shortcut-for-your-iPad',
  //'https://patriotpals.zendesk.com/hc/en-us/articles/360052007374-Create-a-shortcut-for-your-Android',
  desktop_web_shortcut: '#',//'https://patriotpals.zendesk.com/hc/en-us/sections/360009540253-Create-Mobile-App-Shortcut-For-Your-Device',
  video_tour: '#',//'https://patriotpals.webflow.io/',

  pals_post_placeholder: 'Share your thoughts with other Fans, upload or copy/paste photos and articles about your favorite sporting events and topics',
  openforum_post_placeholder: 'Share your opinions and news with fellow conservatives and your progressive friends',
  faqUrl: '#',//'https://patriotpals.zendesk.com/,
  badgeClass: 'mdi mdi-checkbox-marked-circle',
  emoji_path: "assets/images/emoji/",

  firebase: {
    apiKey: "AIzaSyA-XSMenqPmCSEY-NWmBNpkusoAEnHg6Pg",
    authDomain: "igt-push-notificatios.firebaseapp.com",
    projectId: "igt-push-notificatios",
    storageBucket: "igt-push-notificatios.appspot.com",
    messagingSenderId: "336919144843",
    appId: "1:336919144843:web:83aac99f511688ccbb678e",
    measurementId: "G-QDPEMHWQLC",
    vapidKey: "AAAATnHyKYs:APA91bE4oSFID6RRTcCX2m1Ot0eCpA_RM9CWW5xe9K2ytrs-4pY8ZcfH2avRH6sgQBJOck4UZzve4BqJ5edlfJQWS9jic-x2Iae5DhvY08VTYT9GY7Os3fXIH-eV1ZiP4IwU7EkO7qLX"
  },

  //*****Patriotpals*****// 


  //*****Progressivepals*****// 
  // platform_name: "Progressivepals",
  // other_platform_name: "Patriotpals",
  // platform_logo: "assets/images/logo_progressive.png",
  // meta_logo: "assets/images/metalogo_progressive.jpg",
  // login_meta: "assets/images/progressive_login_meta.jpg",
  // other_platform_logo: "assets/images/logo.png",
  // project_url: "https://staging.progressivepals.com",
  // admin_url: "https://staging.progressivepals.com/admin1987",
  // base_url: "https://api.progressivepals.com/api",       // Progressivepals live server API
  // siteMap_link:'https://staging.progressivepals.com/sitemap.xml',
  // socket_base_url: "https://api.patriotpals.com:4000",
  // social_media_url: "https://api.progressivepals.com:3500",       //Social media login base url
  // other_social_media_url: "https://api.patriotpals.com:3000",      //Social media login base url
  // user_profile_img: "assets/images/users/default-user.png",
  // user_cover_img: "assets/images/background/login-register-black-1.jpg",
  // platform_id: "2",
  // alias_mode_name: "Alias Mode",  // Alias Mode name in Open Forum.
  // alias_user_name: "Alias",  // Alias user name in Open Forum.
  // alias_lower_case_user_name: "alias",  // Alias user name in Open Forum.
  // svg: "svg_progressive", // making the svg icons dynamics for svg_progressive pals
  // comment_icon: "assets/images/progressive_icon/comment.svg",
  // share_icon: "assets/images/progressive_icon/share.svg",
  // tagTeam_icon: "assets/images/progressive_icon/tagTeam.svg",
  // like_icon: "assets/images/progressive_icon/like.svg",
  // like_outline_icon: "assets/images/progressive_icon/like-outline.svg",
  // shout_icon: "assets/images/progressive_icon/shout.svg",
  // pals_bg: "url('assets/images/pals_progressive.jpg')",
  // open_forum_bg: "url('assets/images/open_forum_progressive.jpg')",
  // open_forum_alias_bg: "url('assets/images/open_forum_alias_progressive.jpg')",
  // faq_path: "assets/images/faq-progressive/",
  // faq_name: "Progressive Pals",
  // blog_img: "assets/images/progressive_blog.jpeg",
  // homeContent: 'Progressive Pals is a progressive uncensored social media platform designed to unite fellow progressives in the common interest of inclusivity, forward thinking and personal freedom.  We strive to provide our members a place where you may share your open minded and free flowing ideas without the stress of conservative ridicule.  Progressive Pals is a place for fellow progressives to meet and share their life experiences with their family and friends through the submission of photos, videos and opinions.<br><br>We also offer the Open Forum within Progressive Pals allowing those of all ideological backgrounds to engage in political, social, and religious discourse.  The Open Forum allows its members to either post under their real identity or under an alias known as "Alias Mode".  We hope you enjoy Progressive Pals and please help us to spread the word to your friends who share your passion!',
  // popup_content_singular: 'Progressive',
  // popup_content:'progressives',
  // other_popup_content:'conservatives',
  // iphone_web_shortcut: 'https://progressivepals.zendesk.com/hc/en-us/articles/360052010254-Creating-a-shortcut-for-your-iPhone',
  // ipad_web_shortcut: 'https://progressivepals.zendesk.com/hc/en-us/articles/360052513554-Create-a-shortcut-for-your-iPad',
  // android_web_shortcut: 'https://progressivepals.zendesk.com/hc/en-us/articles/360052768253-Create-a-shortcut-for-your-Android',
  // desktop_web_shortcut: 'https://progressivepals.zendesk.com/hc/en-us/sections/360009540653-Create-Mobile-App-Shortcut-For-Your-Device',
  // video_tour :'https://progressivepals.webflow.io/',
  // help_center: 'https://progressivepals.zendesk.com/hc/en-us',
  // pals_post_placeholder: 'Share your thoughts, upload or copy/paste photos with your progressive friends and family',
  // openforum_post_placeholder: 'Share your opinions and news with fellow progressives and your conservative friends',
  // faqUrl:'https://progressivepals.zendesk.com/',
  // badgeClass: 'mdi mdi-checkbox-marked-circle',
  //*****Progressivepals*****// 
};
