import { Injectable, Inject, } from '@angular/core';

import { DOCUMENT } from '@angular/common';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CanonicalService {

  constructor(@Inject(DOCUMENT) private dom) { }

  project_url: any;
  full_roure_link: any = '';
  setCanonicalURL(url?: string) {

    this.project_url = environment.project_url;

    var uC = this.dom.querySelectorAll("link[rel='canonical']")[0];

    const canURL = url == undefined ? this.dom.URL : url;
    const link: HTMLLinkElement = this.dom.createElement('link');

    this.full_roure_link = canURL.replace("http://", "https://");

    link.setAttribute('href', this.full_roure_link);
    link.setAttribute('rel', 'canonical');

    if (uC == undefined) {
      this.dom.head.appendChild(link);
    } else {
      uC.setAttribute("href", this.full_roure_link);
    }


  }

}