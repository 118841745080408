import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: 'addressPipe'
})
export class AddressPipe implements PipeTransform {
	transform(user_details: any) {
		let data = '';

		if (user_details['city']) {
			data += user_details['city'];
		}
		if (user_details['state']) {
			data += ', ' + user_details['state'];
		}
		if (user_details['country']) {
			data += ' (' + user_details['country'].toUpperCase() + ')'
		}

		return data;
	}
}