export const customEmojisBasketball: any = [
    // {
    //     name: 'GoldenTrophy',
    //     shortNames: ['Golden-Trophy'],
    //     custom: true,
    //     text: '(Golden-Trophy)',
    //     emoticons: [],
    //     keywords: ['Golden-Trophy', 'basketball', 'cup'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750701-trophy-2.png',
    // },
    // {
    //     name: 'Vest',
    //     shortNames: ['vest'],
    //     custom: true,
    //     text: '(vest)',
    //     emoticons: [],
    //     keywords: ['vest', 'basketball', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665750933-vest-1.png',
    // },
    // {
    //     name: 'Shot',
    //     shortNames: ['shot'],
    //     custom: true,
    //     text: '(shot)',
    //     emoticons: [],
    //     keywords: ['shot', 'basketball', 'swing'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751212-shot-1.png',
    // },
    // {
    //     name: 'Dribble',
    //     shortNames: ['dribble'],
    //     custom: true,
    //     text: '(dribble)',
    //     emoticons: [],
    //     keywords: ['dribble', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665751519-dribble-1.png',
    // },
    // {
    //     name: 'Basketballplayerscoring',
    //     shortNames: ['basketball-player-scoring'],
    //     custom: true,
    //     text: '(basketball-player-scoring)',
    //     emoticons: [],
    //     keywords: ['player', 'basketball', 'shot'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665752033-shot.png',
    // },
    // {
    //     name: 'Winner',
    //     shortNames: ['winner'],
    //     custom: true,
    //     text: '(winner)',
    //     emoticons: [],
    //     keywords: ['player', 'winner',],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665752247-winner.png',
    // },
    // {
    //     name: 'Playerinaction',
    //     shortNames: ['player-in-action'],
    //     custom: true,
    //     text: '(player-in-action)',
    //     emoticons: [],
    //     keywords: ['basketballplayer', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665752786-basketball-player-6.png',
    // },
    // {
    //     name: 'Basketballground',
    //     shortNames: ['basketball-ground'],
    //     custom: true,
    //     text: '(basketball-ground)',
    //     emoticons: [],
    //     keywords: ['basketballgame', 'basket', 'ball','basketball-ground'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753123-basketball-game.png',
    // },
    // {
    //     name: 'Ballinbasket',
    //     shortNames: ['ball-in-basket'],
    //     custom: true,
    //     text: '(ball-in-basket)',
    //     emoticons: [],
    //     keywords: ['basketball ', 'basket', 'ball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753388-basketball.png',
    // },
    // {
    //     name: 'Court',
    //     shortNames: ['court'],
    //     custom: true,
    //     text: '(court)',
    //     emoticons: [],
    //     keywords: ['basketball court', 'basketball', 'ball', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753714-basketball-court.png',
    // },
    // {
    //     name: 'Basketball',
    //     shortNames: ['basketball2'],
    //     custom: true,
    //     text: '(basketball2)',
    //     emoticons: [],
    //     keywords: ['ball ', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1665753900-basketball-10.png',
    // },
    // {
    //     name: 'Badge',
    //     shortNames: ['badge'],
    //     custom: true,
    //     text: '(badge)',
    //     emoticons: [],
    //     keywords: ['badge', 'reward'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-badge.png',
    // },
    // {
    //     name: 'Starbadges',
    //     shortNames: ['starbadges'],
    //     custom: true,
    //     text: '(starbadges)',
    //     emoticons: [],
    //     keywords: ['starbadges','badges', 'award', 'reward'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-badges.png',
    // },
    // {
    //     name: 'Ballonbasket',
    //     shortNames: ['ball-on-basket'],
    //     custom: true,
    //     text: '(ball-on-basket)',
    //     emoticons: [],
    //     keywords: ['ballonbasket', 'basketball', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-ball-on-basket.png',
    // },
    // {
    //     name: 'Ballandbasket',
    //     shortNames: ['ball-and-basket'],
    //     custom: true,
    //     text: '(ball-and-basket)',
    //     emoticons: [],
    //     keywords: ['basket', 'ballandbasket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-ballandbasket.png',
    // },
    // {
    //     name: 'Basketballwithbasket',
    //     shortNames: ['Basketball-with-basket'],
    //     custom: true,
    //     text: '(Basketball-with-basket)',
    //     emoticons: [],
    //     keywords: ['basket', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-basketball-13.png',
    // },
    // {
    //     name: 'Threepoints',
    //     shortNames: ['three-points'],
    //     custom: true,
    //     text: '(three-points)',
    //     emoticons: [],
    //     keywords: ['basket', 'basketball', 'three-points'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096066-three-points.png',
    // },
    // {
    //     name: 'Store',
    //     shortNames: ['store'],
    //     custom: true,
    //     text: '(store)',
    //     emoticons: [],
    //     keywords: ['store', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096066-store.png',
    // },
    // {
    //     name: 'Trophy',
    //     shortNames: ['trophy'],
    //     custom: true,
    //     text: '(trophy)',
    //     emoticons: [],
    //     keywords: ['trophy', 'basketball', 'award'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096056-trophy-2.png',
    // },
    // {
    //     name: 'Stadium',
    //     shortNames: ['stadium'],
    //     custom: true,
    //     text: '(stadium)',
    //     emoticons: [],
    //     keywords: ['stadium', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096047-stadium-3.png',
    // },
    // {
    //     name: 'Baketballonstadium',
    //     shortNames: ['baketball-on-stadium'],
    //     custom: true,
    //     text: '(baketball-on-stadium)',
    //     emoticons: [],
    //     keywords: ['stadium', 'basketball', 'ground', 'baketballonstadium'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096047-stadium-2.png',
    // },
    // {
    //     name: 'Openstadium',
    //     shortNames: ['open-stadium'],
    //     custom: true,
    //     text: '(open-stadium)',
    //     emoticons: [],
    //     keywords: ['stadium', 'openstadium', 'ground', 'baketballstadium'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-stadium-1.png',
    // },
    // {
    //     name: 'Spinbasketball',
    //     shortNames: ['spin-basketball'],
    //     custom: true,
    //     text: '(spin-basketball)',
    //     emoticons: [],
    //     keywords: ['spinbasketball', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-spinbasketball.png',
    // },
    // {
    //     name: 'Shooting',
    //     shortNames: ['shooting'],
    //     custom: true,
    //     text: '(shooting)',
    //     emoticons: [],
    //     keywords: ['shooting', 'basketball', 'playerinaction'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-shooting.png',
    // },
    // {
    //     name: 'Basketballplayershoes',
    //     shortNames: ['basketballplayershoes'],
    //     custom: true,
    //     text: '(basketballplayershoes)',
    //     emoticons: [],
    //     keywords: ['playerquipment', 'basketballplayershoes'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-shoes-1.png',
    // },
    // {
    //     name: 'Referee',
    //     shortNames: ['referee'],
    //     custom: true,
    //     text: '(referee)',
    //     emoticons: [],
    //     keywords: ['referee', 'basketball', 'womenreferee', 'whistle'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-referee.png',
    // },
    // {
    //     name: 'Podium',
    //     shortNames: ['Podium'],
    //     custom: true,
    //     text: '(Podium)',
    //     emoticons: [],
    //     keywords: ['podium.', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096046-podium.png',
    // },
    // {
    //     name: 'Playing',
    //     shortNames: ['playing'],
    //     custom: true,
    //     text: '(playing)',
    //     emoticons: [],
    //     keywords: ['playing.', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096002-playing.png',
    // },
    // {
    //     name: 'Olympics',
    //     shortNames: ['olympics'],
    //     custom: true,
    //     text: '(olympics)',
    //     emoticons: [],
    //     keywords: ['olympics', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096002-olympics.png',
    // },
    // {
    //     name: 'Medal',
    //     shortNames: ['medal'],
    //     custom: true,
    //     text: '(medal)',
    //     emoticons: [],
    //     keywords: ['medal', 'rewad', 'award'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096002-medal-2.png',
    // },
    // {
    //     name: 'Lovebasketball',
    //     shortNames: ['love-basketball'],
    //     custom: true,
    //     text: '(love-basketball)',
    //     emoticons: [],
    //     keywords: ['love-basketball', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096002-lovebasketball.png',
    // },
    // {
    //     name: 'Jersey',
    //     shortNames: ['jersey'],
    //     custom: true,
    //     text: '(jersey)',
    //     emoticons: [],
    //     keywords: ['jersey', 'dress', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096002-jersey-basketball.png',
    // },
    // {
    //     name: 'Basketballgame',
    //     shortNames: ['basketball-game'],
    //     custom: true,
    //     text: '(basketball-game)',
    //     emoticons: [],
    //     keywords: ['basketball-game', 'basketball', 'player', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666096001-game-3.png',
    // },
    // {
    //     name: 'Dribblebasketball',
    //     shortNames: ['dribble-basketball'],
    //     custom: true,
    //     text: '(dribble-basketball)',
    //     emoticons: [],
    //     keywords: ['dribble-basketball', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-dribble-basketball-1.png',
    // },
    // {
    //     name: 'Coach',
    //     shortNames: ['coach'],
    //     custom: true,
    //     text: '(coach)',
    //     emoticons: [],
    //     keywords: ['coach', 'basketball',],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-coach.png',
    // },
    // {
    //     name: 'Catchbasketball',
    //     shortNames: ['catch-basketball'],
    //     custom: true,
    //     text: '(catch-basketball)',
    //     emoticons: [],
    //     keywords: ['catchbasketball', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-catchbasketball.png',
    // },
    // {
    //     name: 'Board',
    //     shortNames: ['board'],
    //     custom: true,
    //     text: '(board)',
    //     emoticons: [],
    //     keywords: ['board', 'field'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-board.png',
    // },
    // {
    //     name: 'Basketballonbench',
    //     shortNames: ['basketball-on-bench'],
    //     custom: true,
    //     text: '(basketball-on-bench)',
    //     emoticons: [],
    //     keywords: ['basketballonbench', 'bench', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-bench.png',
    // },
    // {
    //     name: 'Basketballtournament',
    //     shortNames: ['basketball-tournament'],
    //     custom: true,
    //     text: '(basketball-tournament.)',
    //     emoticons: [],
    //     keywords: ['basketballtournament', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-basketball-tournament.png',
    // },
    // {
    //     name: 'Players',
    //     shortNames: ['players'],
    //     custom: true,
    //     text: '(players)',
    //     emoticons: [],
    //     keywords: ['basketballplayers', 'players', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-basketball-players.png',
    // },
    // {
    //     name: 'Spinbasketballplayer',
    //     shortNames: ['spin-basketball-player'],
    //     custom: true,
    //     text: '(spin-basketball-player)',
    //     emoticons: [],
    //     keywords: ['basketball-player', 'basketball', 'player', 'spin'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-basketball-player-9.png',
    // },
    // {
    //     name: 'Manbasketballplayer',
    //     shortNames: ['man-basketball-player'],
    //     custom: true,
    //     text: '(man-basketball-player)',
    //     emoticons: [],
    //     keywords: ['manbasketballplayer', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095977-basketball-player-8.png',
    // },
    // {
    //     name: 'Bankshotplayer',
    //     shortNames: ['bank-shot-player'],
    //     custom: true,
    //     text: '(bank-shot-player)',
    //     emoticons: [],
    //     keywords: ['bankshotplayer', 'basketball', 'player'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-player-6.png',
    // },
    // {
    //     name: 'Basketballjersey',
    //     shortNames: ['basketball-jersey'],
    //     custom: true,
    //     text: '(basketball-jersey)',
    //     emoticons: [],
    //     keywords: ['basketballjersey', 'basketball', 'jersey'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-jersey-1.png',
    // },
    // {
    //     name: 'Basketballicons',
    //     shortNames: ['basket-ballicons'],
    //     custom: true,
    //     text: '(basket-ballicons)',
    //     emoticons: [],
    //     keywords: ['basketballicons', 'basketball', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketballicons.png',
    // },
    // {
    //     name: 'Basketballhoop',
    //     shortNames: ['basketball-hoop'],
    //     custom: true,
    //     text: '(basketball-hoop)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'basket'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-hoop.png',
    // },
    // {
    //     name: 'Hoop',
    //     shortNames: ['hoop'],
    //     custom: true,
    //     text: '(hoop)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'hoop'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-hoop-1.png',
    // },
    // {
    //     name: 'Basketballcourt',
    //     shortNames: ['basketball-court'],
    //     custom: true,
    //     text: '(basketball-court)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'basketballgame', 'hoop', 'basketballcourt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketballgame.png',
    // },
    // {
    //     name: 'Scratchbasketballcourt',
    //     shortNames: ['scratchbasketballcourt'],
    //     custom: true,
    //     text: '(scratchbasketballcourt)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketballcourt', 'basketballgame', 'hoop', 'scratchbasketballcourt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095949-basketball-court-2.png',
    // },
    // {
    //     name: 'Basketballbadge',
    //     shortNames: ['basketball-badge'],
    //     custom: true,
    //     text: '(basketball-badge)',
    //     emoticons: [],
    //     keywords: ['basketballbadge', 'basketball', 'medal', 'rewad'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095948-basketball-badge.png',
    // },
    // {
    //     name: 'Handicapbasketballplayer',
    //     shortNames: ['Handicap-basketball-player'],
    //     custom: true,
    //     text: '(Handicap-basketball-player)',
    //     emoticons: [],
    //     keywords: ['basketballhoop', 'basketball', 'handicapplayer', 'hoop', 'handicapbasketballplayer'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095948-basketball-18.png',
    // },
    // {
    //     name: 'Basketballbrochure',
    //     shortNames: ['basketball-brochure'],
    //     custom: true,
    //     text: '(basketball-brochure)',
    //     emoticons: [],
    //     keywords: ['brochure', 'basketball', 'jersey', 'basketballbrochure'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095828-basketball-brochure.png',
    // },
    // {
    //     name: 'Practicebasketball',
    //     shortNames: ['practice-basketball'],
    //     custom: true,
    //     text: '(practice-basketball)',
    //     emoticons: [],
    //     keywords: ['player', 'basketball', 'practicebasketball', 'hoop', 'basketballcourt'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095828-basketball-17.png',
    // },
    // {
    //     name: 'SlamDunk',
    //     shortNames: ['slam-dunk'],
    //     custom: true,
    //     text: '(slam-dunk)',
    //     emoticons: [],
    //     keywords: ['slamdunk', 'basketballcourt', 'basketballgame', 'hoop'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666095827-basketball-16.png',
    // },
    // {
    //     name: 'Dunkshot',
    //     shortNames: ['dunkshot'],
    //     custom: true,
    //     text: '(dunkshot)',
    //     emoticons: [],
    //     keywords: ['basketball', 'dunkshot', 'player', 'dunk'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666182509-dunkshot.png',
    // },
    // {
    //     name: 'Colouredbasketball',
    //     shortNames: ['coloured-basketball'],
    //     custom: true,
    //     text: '(coloured-basketball)',
    //     emoticons: [],
    //     keywords: ['basketball', 'colouredbasketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-coloured-basketball.png',
    // },
    // {
    //     name: 'Basketring',
    //     shortNames: ['basket-ring'],
    //     custom: true,
    //     text: '(basket-ring)',
    //     emoticons: [],
    //     keywords: ['basket', 'basketring'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666181142-basket-ring.png',
    // },
    // {
    //     name: 'Basketballlogo',
    //     shortNames: ['basketball-logo'],
    //     custom: true,
    //     text: '(basketball-logo)',
    //     emoticons: [],
    //     keywords: ['basketballlogo', 'basketball', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666200379-basketball-logo.png',
    // },
    // {
    //     name: 'Ncaabasketballlogo',
    //     shortNames: ['ncaa-basketball-logo'],
    //     custom: true,
    //     text: '(ncaa-basketball-logo)',
    //     emoticons: [],
    //     keywords: ['ncaabasketballlogo', 'basketball', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666200663-ncaa-basketball-logo.png',
    // },
    // {
    //     name: 'Ncaabasketball',
    //     shortNames: ['ncaa-basketball'],
    //     custom: true,
    //     text: '(ncaa-basketball)',
    //     emoticons: [],
    //     keywords: ['ncaabasketball', 'basketball', 'ncaa'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666200663-ncaa-basketball.png',
    // },
    // {
    //     name: 'Ncaacollagebasketbal',
    //     shortNames: ['ncaa-collage-basketbal'],
    //     custom: true,
    //     text: '(ncaa-collage-basketbal)',
    //     emoticons: [],
    //     keywords: ['ncaacollagebasketbal', 'basketball'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666200663-ncaa-collage-basketball.png',
    // },
    // {
    //     name: 'ncaa-logo',
    //     shortNames: ['ncaa-logo'],
    //     custom: true,
    //     text: '(ncaa-logo)',
    //     emoticons: [],
    //     keywords: ['ncaa-logo', 'basketball', 'ncaa', 'logo'],
    //     imageUrl: 'https://itsgt.nyc3.digitaloceanspaces.com/sample/1666200663-ncaa-logo.png',
    // }

]